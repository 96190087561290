import { ANSWER_HEADERS } from "./Config";

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
export function propComparator(prop) {
  return function (a, b) {
    return a[prop] - b[prop];
  };
}

export function debugPrint(e, color = "") {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // return;
    console.groupCollapsed(`%c${e}`, color === "" ? "" : `color: ${color}`);
    console.trace();
    console.groupEnd();
  }
}

export function getProperAnswerPreview(i) {
  if (i === -1) {
    return "";
  }
  return ANSWER_HEADERS[i];
}

export function checkIfStringIsNotEmpty(str) {
  return str !== undefined && str !== null && str !== "";
}

export function stripNonAlphabetNumber(str) {
  //only keep dash, underscore, alphabet, number
  return str.replace(/[^a-zA-Z0-9-_]/g, "");
}

export function getRealCategory(subQuestion, masterQuestion) {
  if (checkIfStringIsNotEmpty(subQuestion.mock_category)) {
    return subQuestion.mock_category;
  }
  if (checkIfStringIsNotEmpty(subQuestion.category)) {
    return subQuestion.category;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.mock_category)) {
    return masterQuestion.mock_category;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.category)) {
    return masterQuestion.category;
  }
  return "";
}

export function getRealType(subQuestion, masterQuestion) {
  if (checkIfStringIsNotEmpty(subQuestion.mock_type)) {
    return subQuestion.mock_type;
  }
  if (checkIfStringIsNotEmpty(subQuestion.type)) {
    return subQuestion.type;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.mock_type)) {
    return masterQuestion.mock_type;
  }
  if (checkIfStringIsNotEmpty(masterQuestion.type)) {
    return masterQuestion.type;
  }
  return "";
}

export function mapValueRangeToNewRange(value, oldMin, oldMax, newMin, newMax) {
  return Math.floor(
    ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin
  );
}

export function replaceContextForUnderline(context) {
  return context.replaceAll(
    "####",
    `<span class="no-letter-spacing">____</span>`
  );
}

export function htmlHasContent(html) {
  // check if html code provided has any content, it can be <p></p> or <p>   </p>, which is empty
  // or <div><p>This is something</p></div>, which is not empty
  // or <div><p></p></div>, which is empty
  // or <div><p>   </p></div>, which is empty
  // or <div><p>   </p><p>   </p></div>, which is empty
  //
  // parse html code into DOM

  // if html is not empty and it does not contain html tags, then it is not empty
  if (html !== "" && html.indexOf("<") === -1) {
    return true;
  }

  var parser = new DOMParser();
  var doc = parser.parseFromString(html, "text/html");

  // if it is not a valid html code, but string is not empty, then it is true
  if (doc.body.innerHTML === html && html !== "") {
    // get all the text nodes in the DOM, where it is <p> or <div> or <span>
    var textNodes = doc.querySelectorAll("p, div, span");

    // if there is no text node, then it is empty
    if (textNodes.length === 0) {
      return false;
    }
    // if there is text node, then check if it is empty
    else {
      // if there is any text node that is not empty, then it is not empty
      for (var i = 0; i < textNodes.length; i++) {
        if (textNodes[i].textContent.trim() !== "") {
          return true;
        }
      }
      // if all the text nodes are empty, then it is empty
      return false;
    }
  }

  return false;
}
