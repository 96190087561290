import React from "react";
import GoldTrophy from "../../assets/images/gold_trophy.png";
import SilverTrophy from "../../assets/images/silver_trophy.png";
import BronzeTrophy from "../../assets/images/bronze_trophy.png";
import BeginnerTrophy from "../../assets/images/beginner_trophy.png";
import ContentTitle from "../widgets/ContentTitle";
import withRouter from "../../utilities/withRouter";
import {
  clientGetResult,
  clientGetResultPreview,
} from "../../utilities/Requests";

import BG_Grey from "../../assets/images/bg_grey.jpg";
import {
  checkIfStringIsNotEmpty,
  getProperAnswerPreview,
  getRealCategory,
  getRealType,
  mapValueRangeToNewRange,
} from "../../utilities/Utilities";
import QuestionWithAnswer from "../widgets/QuestionWithAnswer";
import { RulesOptions } from "../../utilities/Config";
import FlatButton from "../widgets/FlatButton";
import PencilAnswer from "../widgets/PencilAnswer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class QuizMockPaperResultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trophy: 0,
      currentPaper: { questions: [] },
      abilityResult: {},
      allAnswers: [],
      mode: 1,
      currentQuestion: 0,
      pencilPosition: 0,
    };

    this.getAbilityResultInfo = this.getAbilityResultInfo.bind(this);
    this.goPreviousPage = this.goPreviousPage.bind(this);
    this.goNextPage = this.goNextPage.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.getQuizResult();
    // scroll event
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    // check position of question-with-answer
    var questionWithAnswer = document.getElementById("answers-wrapper");
    if (questionWithAnswer) {
      //   console.log(questionWithAnswer.offsetTop, window.scrollY);
      this.setState({
        pencilPosition: Math.max(
          window.scrollY - questionWithAnswer.offsetTop + 75,
          0
        ),
      });
    }
  }

  async getQuizResult() {
    let answerID = this.props.params.id;
    let result = {};
    if (this.props.params.preview) {
      this.setState({ isPreview: true });
      result = await clientGetResultPreview(
        answerID,
        this.props.params.preview
      );
    } else {
      result = await clientGetResult(answerID);
    }
    if (!result.paper) {
      window.location.href = "../404";
    }

    this.setState(
      { currentPaper: result.paper, answers: result.answers },
      this.getAbilityResultInfo
    );
  }

  getAbilityResultInfo() {
    var answerList = {};

    var currentCount = 0;

    if (!this.state.currentPaper) {
      return {};
    }
    for (let i = 0; i < this.state.currentPaper.questions.length; i++) {
      for (
        var j = 0;
        j < this.state.currentPaper.questions[i].all_questions.length;
        j++
      ) {
        let _ = this.state.currentPaper.questions[i].all_questions[j];
        var answerInfo = {};
        for (var k = 0; k < this.state.answers.answers.length; k++) {
          let eachAnswer = this.state.answers.answers[k];
          if (eachAnswer.id === _._id) {
            answerInfo = eachAnswer;
            answerInfo["category"] = getRealCategory(
              _,
              this.state.currentPaper.questions[i]
            );
            answerInfo["type"] = getRealType(
              _,
              this.state.currentPaper.questions[i]
            );
          }
        }

        answerList[currentCount] = answerInfo;
        currentCount += 1;
      }
    }

    let abilityResult = {};
    // loop through all the answers
    var score = 0;
    var total = 0;
    for (let i = 0; i < Object.keys(answerList).length; i++) {
      let eachAnswer = answerList[i];
      let category = eachAnswer.category;
      let correct = eachAnswer.answer === eachAnswer.correct;
      score += correct;
      total += 1;
      if (!abilityResult[category]) {
        abilityResult[category] = {
          correct: correct ? 1 : 0,
          total: 1,
        };
      } else {
        abilityResult[category].correct += correct;
        abilityResult[category].total += 1;
      }
    }
    // map score that has the value from 0 to 1 to 1 to 3 dynamically
    // 0 -> 1
    // 1 -> 3
    // 0.5 -> 2
    let trophy = mapValueRangeToNewRange(score / total, 0, 1, 1, 3);
    if (score >= 36) {
      trophy = 3;
    }
    // score = 6 ~ 10
    else if (score >= 18) {
      trophy = 2;
    }
    // score = 1 ~ 5
    else if (score >= 2) {
      trophy = 1;
    }
    // score = 0
    else {
      trophy = 0;
    }
    this.setState({
      abilityResult: abilityResult,
      trophy: trophy,
      score: score,
      total: total,
    });
  }

  getColor() {
    return "grey";
  }

  properTrophy() {
    switch (this.state.trophy) {
      case 0:
      default:
        return BeginnerTrophy;
      case 1:
        return BronzeTrophy;
      case 2:
        return SilverTrophy;
      case 3:
        return GoldTrophy;
    }
  }

  getAnswerList() {
    if (!this.state.currentPaper) {
      return {};
    }
    var answerList = {};

    var currentCount = 0;

    if (!this.state.currentPaper) {
      return {};
    }
    for (var i = 0; i < this.state.currentPaper.questions.length; i++) {
      for (
        var j = 0;
        j < this.state.currentPaper.questions[i].all_questions.length;
        j++
      ) {
        let _ = this.state.currentPaper.questions[i].all_questions[j];
        var answerInfo = {};
        for (var k = 0; k < this.state.answers.answers.length; k++) {
          let eachAnswer = this.state.answers.answers[k];
          if (eachAnswer.id === _._id) {
            answerInfo = eachAnswer;
          }
        }

        answerList[currentCount] = answerInfo;
        currentCount += 1;
      }
    }
    Object.keys(this.state.allAnswers).forEach(eachAnswer => {
      answerList[eachAnswer] = this.state.allAnswers[eachAnswer];
    });

    return answerList;
  }

  goPreviousPage() {
    this.setState(
      {
        currentQuestion: Math.max(0, this.state.currentQuestion - 1),
      },
      this.collapseExplanations
    );
  }

  goNextPage() {
    this.setState(
      {
        currentQuestion: Math.min(
          this.state.currentPaper.questions.length - 1,
          this.state.currentQuestion + 1
        ),
      },
      this.collapseExplanations
    );
  }

  getStartingNumber(index) {
    var startingNumber = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (i === index) {
        return false;
      }
      startingNumber += eachQuestion.all_questions.length;
      return true;
    });
    return startingNumber;
  }

  getPageNumber(index) {
    var startingNumber = 0;
    var page = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (index >= startingNumber) {
        page = i;
        startingNumber += eachQuestion.all_questions.length;
        return true;
      }
      return false;
    });

    return page;
  }

  getAbilityPage(ability) {
    let returnValue = "";
    Object.keys(RulesOptions).forEach(eachType => {
      if (RulesOptions[eachType].category === ability) {
        returnValue = eachType;
      }
    });
    return returnValue;
  }

  findAnswer(answerList, id) {
    for (let i = 0; i < Object.keys(answerList).length; i++) {
      if (answerList[i].id === id) {
        return answerList[i];
      }
    }
    return null;
  }

  getBarColor(type) {
    if (type === "閱讀單題") {
      return "#86C348";
    }
    if (type === "語文") {
      return "#4A8FCF";
    }
    if (type === "題組") {
      return "#A781CE";
    }
  }

  getAnalysis(answerList) {
    let questions = this.state.currentPaper.questions;
    var data = {};
    questions.forEach(eachQuestion => {
      eachQuestion.all_questions.forEach(eachSubQuestion => {
        let type = getRealType(eachSubQuestion, eachQuestion);
        let category = getRealCategory(eachSubQuestion, eachQuestion);
        let mockType = eachQuestion.mock_type;
        let answer = this.findAnswer(answerList, eachSubQuestion._id);
        if (!checkIfStringIsNotEmpty(mockType)) {
          mockType = type;
        }
        // console.log(type, category, mockType);
        if (data[mockType]) {
          if (data[mockType][category]) {
            data[mockType][category].correct +=
              answer.answer === answer.correct ? 1 : 0;
            data[mockType][category].total += 1;
          } else {
            data[mockType][category] = {
              correct: answer.answer === answer.correct ? 1 : 0,
              total: 1,
            };
          }
        } else {
          data[mockType] = {};
          data[mockType][category] = {
            correct: answer.answer === answer.correct ? 1 : 0,
            total: 1,
          };
        }
      });
    });

    let returnData = [];
    Object.keys(data).forEach(eachMockType => {
      //   const labels = Object.keys(data[eachMockType]);

      const options = {
        scales: {
          y: {
            max: 100,
            min: 0,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: eachMockType,
          },
        },
      };

      let beautifyLabel = eachMockType;
      if (eachMockType === "語文") {
        beautifyLabel = "語文應用";
      }

      if (eachMockType === "題組") {
        beautifyLabel = "閱讀題組";
      }

      let datasets = {
        label: beautifyLabel,
        data: [],
        backgroundColor: this.getBarColor(eachMockType),
      };
      Object.keys(data[eachMockType]).forEach(eachCategory => {
        // console.log(data[eachMockType][eachCategory]);
        datasets["data"].push(
          (data[eachMockType][eachCategory].correct /
            data[eachMockType][eachCategory].total) *
            100
        );
      });

      returnData.push({
        data: {
          labels: Object.keys(data[eachMockType]),
          datasets: [datasets],
        },
        options: options,
      });
    });

    return returnData;
  }

  render() {
    var answerList = this.getAnswerList();
    let analysis = this.getAnalysis(answerList);

    return (
      <div
        style={{
          backgroundImage: `url(${BG_Grey})`,
        }}>
        <div
          className="center result-page-parent"
          style={{
            width: "100vw",
            padding: 32,
            boxSizing: "border-box",
            overflow: "hidden",
            backgroundSize: "cover",
          }}>
          <div className="full-wh center">
            <div className="result-content-area">
              <div
                className="two-third-width center-v"
                style={{ paddingTop: 32 }}>
                <div className="center">
                  <ContentTitle
                    label={`你的${this.state.currentPaper.type ?? ""}能力是`}
                    isLarge={true}
                    color={this.getColor()}
                    nowrap={true}
                  />
                </div>
                <p className="score-description">
                  <span>答對題數</span>
                  <span className="score-highlight">{this.state.score}</span>
                  <span>/{Object.keys(answerList).length}題</span>
                </p>
                <div className="m-xxl-t">
                  <FlatButton
                    label="去做其他模擬試卷"
                    curve={true}
                    href={`../quiz-rule/mock-paper`}
                    callback={() => {
                      //   window.location.href = `../quiz-rule/mock-paper`;
                    }}
                  />
                </div>
              </div>
              <img
                alt="trophy"
                className="trophy large"
                src={this.properTrophy()}
              />
            </div>
          </div>
        </div>

        <div className="mock-paper-details-analysis-wrapper">
          <div className="center flex-v">
            <p className="mock-paper-details-analysis-title">正答率</p>
            <p>(%)</p>
          </div>
          {analysis.map((eachAnalysis, i) => {
            return (
              <div
                className="mock-paper-details-analysis"
                key={`analysis-${eachAnalysis}-${i}`}>
                <Bar options={eachAnalysis.options} data={eachAnalysis.data} />
              </div>
            );
          })}
        </div>

        <div className="">
          <div className="flex space-between rel" style={{ zIndex: 1 }}>
            <div className="flex flex-v space-between">
              <div className="details-result-tab-parent">
                {/* <div
                    onClick={() => {
                      this.setMode(0);
                    }}
                    className={`tab-button-green noselect ${this.getColor()}`}
                  >
                    答錯題目
                  </div> */}
                <div
                  onClick={() => {
                    this.setMode(1);
                  }}
                  className="tab-button-yellow grey noselect">
                  全部題目
                </div>
              </div>
            </div>
          </div>
          <div
            id="answers-wrapper"
            className="hori-center"
            style={{ justifyContent: "space-between", padding: "0em 2em" }}>
            <div
              id="main-scroller"
              style={{ zIndex: 99, maxWidth: "calc(100% - 256px)" }}
              className={`rel grey  details-result-backdrop no-scroll${
                this.state.mode === 1 ? " all-details" : ""
              } ${this.getColor()}`}>
              {this.state.currentPaper.type === "題組"
                ? this.state.currentPaper.questions.length > 0 &&
                  this.state.currentPaper.questions[
                    this.state.currentQuestion
                  ] && (
                    <QuestionWithAnswer
                      ref={this.articleQuestionWithAnswer}
                      showWrong={true}
                      colorMode={this.state.mode}
                      rawHeight={true}
                      blockHeight={this.props.maxHeight - (235 + 108 + 75)}
                      key={`question-${0}`}
                      answerList={answerList}
                      number={this.getStartingNumber(
                        this.state.currentQuestion
                      )}
                      showExplanation={true}
                      question={
                        this.state.currentPaper.questions[
                          this.state.currentQuestion
                        ]
                      }
                    />
                  )
                : this.state.currentPaper.questions.map((eachQuestion, i) => {
                    return (
                      <QuestionWithAnswer
                        showWrong={true}
                        colorMode={this.state.mode}
                        rawHeight={true}
                        expand={true}
                        blockHeight={this.props.maxHeight - (235 + 108 + 75)}
                        key={`question-${i}-${eachQuestion._id}`}
                        answerList={answerList}
                        number={this.getStartingNumber(i)}
                        showExplanation={true}
                        question={eachQuestion}
                      />
                    );
                  })}
            </div>
            <div
              //   className="details-pencil"
              style={{
                position: "absolute",
                right: 32,
                top: 0,
                transform: `translateY(${this.state.pencilPosition}px)`,
                //   transform: `unset`,
              }}>
              <PencilAnswer
                color={this.getColor()}
                showWrong={true}
                answerList={answerList}
                scrollToQuestion={this.scrollToQuestion}
                getProperAnswerPreview={getProperAnswerPreview}
                needSubmitButton={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(QuizMockPaperResultPage);
