import React from "react";
import GreenCalculationBook from "../../assets/images/green_calculation_book.png";
import PurpleCalculationBook from "../../assets/images/purple_calculation_book.png";
import BlueCalculationBook from "../../assets/images/blue_calculation_book.png";
import GreyCalculationBook from "../../assets/images/grey_calculation_book.png";
import QuestionWithAnswer from "../widgets/QuestionWithAnswer";
import MissingAnswerDialog from "../dialogs/MissingAnswerDialog";
import PencilAnswer from "../widgets/PencilAnswer";
import withRouter from "../../utilities/withRouter";
import BG_Green from "../../assets/images/bg_green.png";
import BG_Blue from "../../assets/images/bg_blue.jpg";
import BG_Purple from "../../assets/images/bg_purple.jpg";
import BG_Grey from "../../assets/images/bg_grey.jpg";
import {
  clientReadPaper,
  clientReadPaperPreview,
  clientSubmitAnswer,
} from "../../utilities/Requests";
import { debugPrint, getProperAnswerPreview } from "../../utilities/Utilities";
import APIResult from "../../configs/APIResult";
import { ANSWER_HEADERS } from "../../utilities/Config";

class QuizPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allQuestions: [],
      allAnswers: {},
      showMissingAnswer: false,
      missingAnswers: [],
      calculating: 0,
      currentQuestion: 0,
      currentPaper: {},
      isPreview: false,
    };

    this.setAnswer = this.setAnswer.bind(this);
    this.checkBeforeSubmit = this.checkBeforeSubmit.bind(this);
    this.checkToNavigate = this.checkToNavigate.bind(this);
    this.goPreviousPage = this.goPreviousPage.bind(this);
    this.goNextPage = this.goNextPage.bind(this);
    this.getPageNumber = this.getPageNumber.bind(this);
    this.scrollToQuestion = this.scrollToQuestion.bind(this);

    this.timeCounterInterval = null;
    this.timeCounterTimer = 0;
  }

  componentDidMount() {
    let paperID = this.props.params.id;
    if (paperID) {
      this.getPaper(paperID);
    }

    if (!this.timeCounterInterval) {
      this.timeCounterInterval = setInterval(() => {
        this.timeCounterTimer += 1;
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.timeCounterInterval) {
      clearInterval(this.timeCounterInterval);
    }
  }

  async getPaper(paperID) {
    // get url params
    let result = {};
    if (this.props.params.preview) {
      this.setState({ isPreview: true });
      result = await clientReadPaperPreview(paperID, this.props.params.preview);
    } else {
      result = await clientReadPaper(paperID);
    }
    if (result.status === APIResult.SUCCESS) {
      debugPrint(JSON.stringify(result.data.questions, null, 2), "yellow");
      let allQuestions = result.data.questions;
      if (result.data.type === "模擬考") {
        allQuestions = this.processMockPaperQuestions(allQuestions);
      }
      this.setState({
        allQuestions: result.data.questions,
        mockPaperQuestions: allQuestions,
        currentQuestion: 0,
        currentPaper: result.data,
      });
    }
  }

  processMockPaperQuestions(questions) {
    var returnQuestions = [];
    var currentQuestionStack = {};
    questions.forEach((eachQuestion, index) => {
      if (Object.keys(currentQuestionStack).includes(eachQuestion.mock_type)) {
        currentQuestionStack[eachQuestion.mock_type].push(eachQuestion);
      } else {
        currentQuestionStack[eachQuestion.mock_type] = [eachQuestion];
      }
    });
    const sequence = ["閱讀單題", "語文"];
    // sequence.forEach(eachSequence => {
    //   if (currentQuestionStack[eachSequence]) {
    //     returnQuestions.push(currentQuestionStack[eachSequence]);
    //   }
    // });
    var tempQuestions = [];
    questions.forEach((eachQuestion, index) => {
      if (sequence.includes(eachQuestion.mock_type)) {
        tempQuestions.push(eachQuestion);
      }
    });
    returnQuestions.push(tempQuestions);
    if (currentQuestionStack["題組"]) {
      currentQuestionStack["題組"].forEach(eachQuestion => {
        returnQuestions.push([eachQuestion]);
      });
    }
    return returnQuestions;
  }

  setAnswer(question, id, answer, correct) {
    var allAnswers = this.state.allAnswers;
    allAnswers[question] = { id: id, answer: answer, correct: correct };
    this.setState({
      allAnswers: allAnswers,
    });
  }

  getAnswerList() {
    var answerList = {};
    if (Object.keys(this.state.allQuestions > this.state.allAnswers)) {
      var currentCount = 0;
      for (var i = 0; i < this.state.allQuestions.length; i++) {
        for (
          var j = 0;
          j < this.state.allQuestions[i].all_questions.length;
          j++
        ) {
          let _ = this.state.allQuestions[i].all_questions[j];

          answerList[currentCount] = {
            id: _._id,
            answer: -1,
            correct: ANSWER_HEADERS.includes(_.correct_answer)
              ? ANSWER_HEADERS.indexOf(_.correct_answer)
              : -1,
          };
          currentCount += 1;
        }
      }
      Object.keys(this.state.allAnswers).forEach(eachAnswer => {
        answerList[eachAnswer] = this.state.allAnswers[eachAnswer];
      });
    } else {
      answerList = this.state.allAnswers;
    }
    return answerList;
  }

  hasEmptyAnswer(answerList) {
    var hasEmpty = false;
    Object.keys(answerList).every(eachAnswer => {
      if (answerList[eachAnswer].answer === -1) {
        hasEmpty = true;
        return false;
      }
      return true;
    });
    return hasEmpty;
  }

  async checkBeforeSubmit() {
    let answerList = this.getAnswerList();
    if (!this.hasEmptyAnswer(answerList)) {
      this.setState({ calculating: 1 });
      this.submitAnswer();
      return;
    }
    var missingAnswers = [];
    Object.keys(answerList).forEach((eachAnswer, index) => {
      if (answerList[eachAnswer]["answer"] === -1) {
        missingAnswers.push(index);
      }
    });
    this.setState({ showMissingAnswer: true, missingAnswers: missingAnswers });
  }

  getPageNumber(index) {
    var startingNumber = 0;
    var page = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (index >= startingNumber) {
        page = i;
        startingNumber += eachQuestion.all_questions.length;
        return true;
      }
      return false;
    });

    return page;
  }

  scrollToQuestion(i) {
    if (this.state.currentPaper.type === "題組") {
      this.setState({ currentQuestion: this.getPageNumber(i) });
    } else {
      let target = document.getElementById(`question-${i}`);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  getColor() {
    let type = this.state.currentPaper.type;
    if (type === "閱讀單題") {
      return "green";
    }
    if (type === "語文") {
      return "blue";
    }
    if (type === "題組") {
      return "purple";
    }
    if (type === "模擬考") {
      return "grey";
    }
  }

  getBackgroundColor() {
    let type = this.state.currentPaper.type;
    if (type === "閱讀單題") {
      return BG_Green;
    }
    if (type === "語文") {
      return BG_Blue;
    }
    if (type === "題組") {
      return BG_Purple;
    }
    if (type === "模擬考") {
      return BG_Grey;
    }
    return "";
  }

  getStartingNumber(index) {
    var startingNumber = 0;
    this.state.allQuestions.every((eachQuestion, i) => {
      if (i === index) {
        return false;
      }
      startingNumber += eachQuestion.all_questions.length;
      return true;
    });

    return startingNumber;
  }

  getMockPaperStartingNumber(index) {
    var startingNumber = 0;
    this.state.mockPaperQuestions.every((eachQuestion, i) => {
      if (i === index) {
        return false;
      }
      eachQuestion.forEach(each => {
        startingNumber += each.all_questions.length;
      });
      return true;
    });

    return startingNumber;
  }

  goPreviousPage() {
    this.setState({
      currentQuestion: Math.max(0, this.state.currentQuestion - 1),
    });
  }

  goNextPage() {
    this.setState({
      currentQuestion: Math.min(
        this.state.allQuestions.length - 1,
        this.state.currentQuestion + 1
      ),
    });
  }

  normalQuestionListComponent(answerList) {
    return this.state.allQuestions.map((eachQuestion, i) => {
      return (
        <QuestionWithAnswer
          blockHeight={this.props.maxHeight - 75}
          key={`question-${i}`}
          setAnswer={this.setAnswer}
          answerList={answerList}
          number={i}
          color={this.getColor()}
          question={eachQuestion}
        />
      );
    });
  }

  articleQuestionListComponent(answerList) {
    return (
      this.state.allQuestions.length > 0 &&
      this.state.allQuestions[this.state.currentQuestion] && (
        <QuestionWithAnswer
          blockHeight={this.props.maxHeight - 75}
          key={`question-with-answer-${0}`}
          setAnswer={this.setAnswer}
          answerList={answerList}
          number={this.getStartingNumber(this.state.currentQuestion)}
          color={this.getColor()}
          question={this.state.allQuestions[this.state.currentQuestion]}
        />
      )
    );
  }

  mockPaperQuestionListComponent(answerList) {
    return this.state.mockPaperQuestions[this.state.currentQuestion].map(
      (eachQuestion, i) => {
        return (
          <QuestionWithAnswer
            key={`question2-${i}`}
            blockHeight={this.props.maxHeight - 75}
            setAnswer={this.setAnswer}
            answerList={answerList}
            number={
              this.getMockPaperStartingNumber(this.state.currentQuestion) + i
            }
            color={this.getColor()}
            question={eachQuestion}
          />
        );
      }
    );
  }

  getQuestionListComponent(answerList) {
    if (this.state.currentPaper.type === "題組") {
      return this.articleQuestionListComponent(answerList);
    }
    if (this.state.currentPaper.type === "模擬考") {
      return this.mockPaperQuestionListComponent(answerList);
    }
    return this.normalQuestionListComponent(answerList);
  }

  shouldShowPageNavigation() {
    return (
      this.state.currentPaper.type === "題組" ||
      this.state.currentPaper.type === "模擬考"
    );
  }

  shouldShowScroll() {
    if (this.state.currentPaper.type === "模擬考") {
      if (
        this.state.mockPaperQuestions[this.state.currentQuestion].length === 1
      ) {
        if (
          this.state.mockPaperQuestions[this.state.currentQuestion][0]
            .mock_type === "題組"
        ) {
          return false;
        }
      }
    }
    return this.shouldShowPageNavigation();
  }

  shouldShowNextPage() {
    if (this.state.currentPaper.type === "模擬考") {
      return (
        this.state.currentQuestion < this.state.mockPaperQuestions.length - 1
      );
    }
    return (
      this.state.currentQuestion < this.state.currentPaper.questions.length - 1
    );
  }

  quizPage() {
    let answerList = this.getAnswerList();
    return (
      <>
        <style>
          {`.scroller{
    overflow: hidden;
  }
  `}
        </style>
        <div className="force-width rel">
          <div
            className="hori-center no-h-scroll bg-fit"
            style={{
              maxHeight: this.props.maxHeight - 75,
              minHeight: this.props.maxHeight - 75,
              background: `url(${this.getBackgroundColor()})`,
              overflowY: "hidden",
            }}>
            <div>
              <div className="mini-gap"></div>
              <div
                className={`question-with-answer-backdrop ${
                  this.shouldShowPageNavigation() ? "" : "scroll-v"
                }`}
                style={{
                  maxHeight: this.shouldShowPageNavigation()
                    ? `calc(${this.props.maxHeight - 75}px - 4em - 64px)`
                    : `calc(${this.props.maxHeight - 75}px - 4em)`,
                  overflow: this.shouldShowScroll() ? "hidden scroll" : "auto",
                }}>
                {this.getQuestionListComponent(answerList)}
              </div>
              {this.shouldShowPageNavigation() && (
                <div className="center gap-l" style={{ marginTop: 10 }}>
                  {this.state.currentQuestion > 0 && (
                    <div
                      onClick={this.goPreviousPage}
                      className={`${this.getColor()}-button secondary next-page-button noselect`}>
                      回上一頁
                    </div>
                  )}
                  {this.shouldShowNextPage() && (
                    <div
                      onClick={this.goNextPage}
                      className={`${this.getColor()}-button next-page-button noselect`}>
                      翻下一頁
                    </div>
                  )}
                </div>
              )}

              <div className="mini-gap"></div>
            </div>
            <PencilAnswer
              color={this.getColor()}
              answerList={answerList}
              scrollToQuestion={this.scrollToQuestion}
              getProperAnswerPreview={getProperAnswerPreview}
              checkBeforeSubmit={this.checkBeforeSubmit}
            />
          </div>
          {this.state.showMissingAnswer ? (
            <MissingAnswerDialog
              color={this.getColor()}
              missingAnswers={this.state.missingAnswers}
              returnFunc={() => {
                this.setState({ showMissingAnswer: false });
              }}
              submitFunc={() => {
                this.submitAnswer();
                this.setState({ showMissingAnswer: false, calculating: 2 });
              }}
            />
          ) : null}
          {this.state.isPreview && (
            <div className="preview-quiz-tips">此為有時限的預覽頁面</div>
          )}
        </div>
      </>
    );
  }

  getCalculationBook() {
    if (this.getColor() === "green") {
      return GreenCalculationBook;
    }
    if (this.getColor() === "blue") {
      return BlueCalculationBook;
    }
    if (this.getColor() === "purple") {
      return PurpleCalculationBook;
    }
    if (this.getColor() === "grey") {
      return GreyCalculationBook;
    }
    return GreenCalculationBook;
  }

  calculatingPage() {
    return (
      <div
        className={`${this.getColor()}-dot center`}
        style={{ height: this.props.maxHeight - 75 }}>
        <div className="center gap-l">
          <img
            alt=""
            className="loading-book"
            src={this.getCalculationBook()}
          />
          <div>
            <div className={`loading-text ${this.getColor()}`}>
              成績計算中...
            </div>
            <div className="center" style={{ marginTop: "1em" }}>
              <span className={`loading-text large ${this.getColor()}`}>
                Loading&nbsp;
              </span>
              <span
                onAnimationEnd={e => {
                  this.checkToNavigate();
                }}
                className={`loading-text xlarge ${this.getColor()} ${
                  this.state.calculating === 1
                    ? "loading-counter-a"
                    : "loading-counter-b"
                }`}
                style={{ "--number": 50 }}></span>
              <span className={`loading-text large ${this.getColor()}`}>%</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async submitAnswer() {
    let answerList = this.getAnswerList();
    var convertedAnswerList = [];

    Object.keys(answerList).forEach((eachAnswer, index) => {
      convertedAnswerList.push(answerList[eachAnswer]);
    });

    let result = await clientSubmitAnswer(
      this.state.currentPaper._id,
      convertedAnswerList,
      null,
      this.timeCounterTimer
    );
    this.setState({ calculating: 2 }, () => {
      this.checkToNavigate(result.id);
    });
  }

  checkToNavigate(id = null) {
    if (this.state.calculating === 2 && id) {
      setTimeout(() => {
        let redirect = "";
        if (this.state.currentPaper.type === "模擬考") {
          redirect = `../mock-paper-result/${id}`;
        } else if (this.state.currentPaper.category === "檢測考題") {
          redirect = `../basic-result/${id}`;
        } else {
          redirect = `../details-result/${id}`;
        }
        if (this.state.isPreview) {
          redirect = `../${redirect}/${this.props.params.preview}`;
        }
        window.location.href = redirect;
      }, 1000);
    }
  }

  render() {
    return this.state.calculating > 0
      ? this.calculatingPage()
      : this.quizPage();
  }
}

export default withRouter(QuizPage);
