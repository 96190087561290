module.exports = {
  StylingOptions: {
    reading: {
      title: "閱讀單題測驗",
      titleColor: "#005A03",
      titleColorBasic: "green",
      full: {
        buttons: [{ title: "前往測驗", url: "reading-full" }],
        class: "orange-button",
      },
      partial: {
        buttons: [
          { title: "詮釋", url: "reading-interpretation" },
          { title: "摘要", url: "reading-summarize" },
          { title: "推論", url: "reading-inference" },
          { title: "統整", url: "reading-conclusion" },
          { title: "分析", url: "reading-analyze" },
        ],
        class: "green-button ",
      },
      color: "#F6FAF4",
    },
    language: {
      title: "語文單題測驗",
      titleColor: "#1F5194",
      titleColorBasic: "blue",
      full: {
        buttons: [{ title: "前往測驗", url: "language-full" }],
        class: "orange-button",
      },
      partial: {
        buttons: [
          { title: "字形", url: "language-form" },
          { title: "讀音", url: "language-pronunciation" },
          { title: "標點", url: "language-punctuation" },
          { title: "恰當詞語", url: "language-proper-word" },
          { title: "字體六書", url: "language-character" },
          { title: "應用文", url: "language-essay" },
        ],
        class: "blue-button",
      },
      color: "#ECFAFF",
    },
    article: {
      title: "閱讀題組測驗",
      titleColor: "#5D4890",
      titleColorBasic: "purple",
      full: {
        buttons: [{ title: "前往測驗", url: "article-full" }],
        class: "orange-button",
      },
      partial: {
        buttons: [
          { title: "訊息短文", url: "article-message" },
          { title: "文學短文", url: "article-literature" },
          { title: "文言文", url: "article-classical" },
          { title: "韻文", url: "article-verse" },
        ],
        class: "purple-button",
      },
      color: "#F1EDF9",
    },
  },

  RulesOptions: {
    "mock-paper": {
      type: "模擬考",
      category: "",
      label: "模擬考題線上測驗",
      color: "grey",
      titleColor: "#784618",
      backgroundColor: "#F3F3F3",
      rules: [
        "試題共42題，每題一個正答。",
        "試題依次為語文單題、閱讀單題、閱讀題組。",
        "上傳答案後，我們會提供得分及閱讀的五種能力分析。",
        "每題２分，所以滿分只有８４分。",
      ],
    },
    "reading-full": {
      type: "閱讀單題",
      category: "檢測考題",
      label: "閱讀單題線上測驗",
      color: "green",
      titleColor: "#005A03",
      backgroundColor: "#F6FAF4",
      rules: [
        "試題共12題，每題一個正答。",
        "試題包含 詮釋、推論、統整、分析四項能力。",
        "上傳答案後，我們會提供閱讀能力評語及閱讀能力分析。",
        "根據閱讀能力分析，你可點選各項閱讀能力，繼續練習。",
        "練習完成，可重回「閱讀能力線上測驗」，重新檢測閱讀能力。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "reading-interpretation": {
      type: "閱讀單題",
      category: "詮釋",
      label: "詮釋能力線上測驗",
      color: "green",
      titleColor: "#005A03",
      backgroundColor: "#F6FAF4",
      rules: [
        "試題共50題，分ＡＢＣＤＥ五組，每組10題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量詞義、句義、填詞、填句四部分。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "reading-summarize": {
      type: "閱讀單題",
      category: "摘要",
      label: "摘要能力線上測驗",
      color: "green",
      titleColor: "#005A03",
      backgroundColor: "#F6FAF4",
      rules: [
        "試題共50題，分ＡＢＣＤＥ五組，每組10題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量作者主要看法、段落主要在說什麼二部分。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "reading-inference": {
      type: "閱讀單題",
      category: "推論",
      label: "推論能力線上測驗",
      color: "green",
      titleColor: "#005A03",
      backgroundColor: "#F6FAF4",
      rules: [
        "試題共50題，分ＡＢＣＤＥ五組，每組10題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量具體推抽象、線索推論、例子推論、原因推論四部分。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "reading-conclusion": {
      type: "閱讀單題",
      category: "統整",
      label: "統整能力線上測驗",
      color: "green",
      titleColor: "#005A03",
      backgroundColor: "#F6FAF4",
      rules: [
        "試題共50題，分ＡＢＣＤＥ五組，每組10題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量細節、比較、推論統整三部分。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "reading-analyze": {
      type: "閱讀單題",
      category: "分析",
      label: "分析能力線上測驗",
      color: "green",
      titleColor: "#005A03",
      backgroundColor: "#F6FAF4",
      rules: [
        "試題共30題，分ＡＢＣ三組，每組10題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｃ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢ二組；如果想挑戰Ａ級分，建議做ＢＣ二組。",
        "試題評量段落結構、寫作技巧、詩詞分析三部分。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-full": {
      type: "語文",
      category: "檢測考題",
      label: "語文單題線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共12題，每題一個正答。",
        "試題包含 字形、讀音、標點、恰當詞語、字體六書、應用文六項能力。",
        "上傳答案後，我們會提供語文能力評語及語文能力分析。",
        "根據語文能力分析，你可點選各項語文能力，繼續練習。如不熟悉字體六書、應用文的基本知識可先點選知識，熟讀內容。",
        "練習完成，可重回「語文能力線上測驗」，重新檢測語文能力。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-form": {
      type: "語文",
      category: "字形",
      label: "字形能力線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共10題，每題一個正答。",
        "試題依難度由低而高的順序安排。愈前面愈容易。",
        "作答完畢，可參考詳解，了解各選項的正確字形。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-pronunciation": {
      type: "語文",
      category: "讀音",
      label: "讀音能力線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共10題，每題一個正答。",
        "試題依難度由低而高的順序安排。愈前面愈容易。",
        "作答完畢，可參考詳解，了解各選項的正確讀音。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-punctuation": {
      type: "語文",
      category: "標點",
      label: "標點能力線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共10題，每題一個正答。",
        "試題依難度由低而高的順序安排。愈前面愈容易。",
        "作答完畢，可參考詳解，了解各選項正確的標點符號。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-proper-word": {
      type: "語文",
      category: "恰當詞語",
      label: "恰當詞語能力線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共20題，每題一個正答。",
        "試題依難度由低而高的順序安排。愈前面愈容易。",
        "作答完畢，可參考詳解了解選擇正答的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-character": {
      type: "語文",
      category: "字體六書",
      label: "字體六書能力單題線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共10題，每題一個正答。",
        "試題依難度由低而高的順序安排。愈前面愈容易。",
        "作答完畢，可參考詳解了解選擇正答的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "language-essay": {
      type: "語文",
      category: "應用文",
      label: "應用文能力線上測驗",
      color: "blue",
      titleColor: "#1F5194",
      backgroundColor: "#ECFAFF",
      rules: [
        "試題共10題，每題一個正答。",
        "試題依難度由低而高的順序安排。愈前面愈容易。",
        "作答完畢，可參考詳解，了解各選項的正確用法。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "article-full": {
      type: "題組",
      category: "檢測考題",
      label: "閱讀題組線上測驗",
      color: "purple",
      titleColor: "#5D4890",
      backgroundColor: "#F1EDF9",
      rules: [
        "試題共16題，每題一個正答。",
        "試題包含 訊息短文、文學短文、文言文、韻文四類文本。",
        "上傳答案後，我們會提供文類閱讀評語及文類閱讀分析。",
        "根據文類閱讀分析，你可點選各類文本繼續練習。",
        "練習完成，你可再回「閱讀題組線上測驗」，重新檢測文類閱讀能力。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "article-message": {
      type: "題組",
      category: "訊息短文",
      label: "訊息短文線上測驗",
      color: "purple",
      titleColor: "#5D4890",
      backgroundColor: "#F1EDF9",
      rules: [
        "試題共25則選文，分ＡＢＣＤＥ五組，每組10-11題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量詮釋、摘要、推論、統整、分析的綜合能力。能力分類在詳解的「出處/能力」說明。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "article-literature": {
      type: "題組",
      category: "文學短文",
      label: "文學短文線上測驗",
      color: "purple",
      titleColor: "#5D4890",
      backgroundColor: "#F1EDF9",
      rules: [
        "試題共24則選文，分ＡＢＣＤＥ五組，每組10-11題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量詮釋、摘要、推論、統整、分析的綜合能力。能力分類在詳解的「出處/能力」說明。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "article-classical": {
      type: "題組",
      category: "文言文",
      label: "文言文線上測驗",
      color: "purple",
      titleColor: "#5D4890",
      backgroundColor: "#F1EDF9",
      rules: [
        "試題共25則選文，分ＡＢＣＤＥ五組，每組10-11題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量詮釋、摘要、推論、統整、分析的綜合能力。能力分類在詳解的「出處/能力」說明。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
    "article-verse": {
      type: "題組",
      category: "韻文",
      label: "韻文線上測驗",
      color: "purple",
      titleColor: "#5D4890",
      backgroundColor: "#F1EDF9",
      rules: [
        "試題共25則選文，分ＡＢＣＤＥ五組，每組10-11題，每題一個正答。",
        "試題依難度由低而高的順序安排。Ａ組最容易，Ｅ組最困難。",
        "如果會考你想挑戰Ｂ級分，建議做ＡＢＣ三組；如果想挑戰Ａ級分，建議做ＣＤＥ三組。",
        "試題評量詮釋、摘要、推論、統整、分析的綜合能力。能力分類在詳解的「出處/能力」說明。",
        "作答完畢，可參考詳解了解做錯的原因。",
        "試題來源如為基測或會考，試題會註明出處如91-01-34，說明此為91年第一次基測第34題。",
        "試題為清楚說明評量能力，會對原題題幹用語略做修改。",
      ],
    },
  },

  PaperNaming: {
    READING: {
      en: "reading",
      cn: "閱讀",
    },
    LANGUAGE: {
      en: "language",
      cn: "語文",
    },
    ARTICLE: {
      en: "article",
      cn: "題組",
    },
  },

  PaperCNtoEN: {
    閱讀單題: "reading",
    語文: "language",
    題組: "article",
  },

  PaperENtoCN: {
    reading: "閱讀單題",
    language: "語文",
    article: "題組",
  },

  ANSWER_HEADERS: ["A", "B", "C", "D"],
};
