import React from "react";
import PropTypes from "prop-types";
import NavigateIcon from "../../assets/images/topbar-navigate-icon.png";
import { debugPrint } from "../../utilities/Utilities";

class TextButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getContent() {
    return (
      <>
        <div className="text-button" onClick={this.props.callback}>
          {this.props.label}
        </div>
        {this.props.hoverMenu && (
          <div className="text-button-hover-menu">
            {Object.keys(this.props.hoverMenu).map((eachMenu, i) => {
              return (
                <div
                  key={`${eachMenu}-${i}`}
                  className={`text-button-hover-menu-item-wrapper ${
                    i === Object.keys(this.props.hoverMenu).length - 1
                      ? "last-child"
                      : ""
                  }`}>
                  <a
                    className={`no-a-style text-button-hover-menu-item ${
                      this.props.hoverMenu[eachMenu].enabled ? "" : "disabled"
                    }`}
                    key={`${eachMenu}-${i}`}
                    href={
                      this.props.hoverMenu[eachMenu].enabled
                        ? this.props.hoverMenu[eachMenu].url
                        : "#"
                    }>
                    <div>{eachMenu}</div>
                    <img
                      className="text-button-hover-menu-navigate-icon"
                      src={NavigateIcon}
                      alt="navigate icon"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }

  render() {
    return this.props.hoverMenu ? (
      <div className="no-a-style text-button-wrapper">{this.getContent()}</div>
    ) : (
      <a className="no-a-style text-button-wrapper" href={`${this.props.url}`}>
        {this.getContent()}
      </a>
    );
  }
}

TextButton.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
  hoverMenu: PropTypes.object,
};

TextButton.defaultProps = {
  label: "Button",
  callback: () => {
    debugPrint("Text Button pressed");
  },
  hoverMenu: null,
};

export default TextButton;
