import React from "react";
import PropTypes from "prop-types";
import PencilTip from "../../assets/images/pencil_tip.svg";

class PencilAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="pencil">
        <div id="pencil-marker" className="">
          <div className="flex-center">
            <div className={`pencil-stick ${this.props.color}`}>
              <div className={`pencil-title ${this.props.color}`}>
                {this.props.label}
              </div>
              <div className={`pencil-preview-answer ${this.props.color}`}>
                {Object.keys(this.props.answerList).map((eachAnswer, index) => (
                  <div
                    onClick={() => {
                      this.props.scrollToQuestion(
                        index,
                        this.props.answerList[eachAnswer]["id"]
                      );
                    }}
                    key={`answer-preview-${index}`}
                    className="pencil-preview-answer-parent">
                    <div
                      className={`pencil-preview-answer-number ${this.props.color}`}>
                      {Number(eachAnswer) + 1}
                    </div>
                    <div
                      className={`pencil-preview-answer-answer ${
                        this.props.showWrong
                          ? this.props.answerList[eachAnswer]["correct"] ===
                            this.props.answerList[eachAnswer]["answer"]
                            ? ""
                            : "wrong"
                          : ""
                      } ${
                        this.props.getProperAnswerPreview(
                          this.props.answerList[eachAnswer]["answer"]
                        ) === "-"
                          ? "dull"
                          : ""
                      }`}>
                      {this.props.showWrong &&
                      this.props.answerList[eachAnswer]["answer"] === -1
                        ? "-"
                        : this.props.getProperAnswerPreview(
                            this.props.answerList[eachAnswer]["answer"]
                          )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="rel">
              <img src={PencilTip} alt="tip" />
              <div className="abs pencil-animation-parent">
                <div
                  className="pencil-animation-img circle-"
                  alt="pencil-mark"></div>
              </div>
            </div>
            {this.props.needSubmitButton ? (
              <div
                className={`${this.props.color}-button button-fit m-m-t`}
                onClick={this.props.checkBeforeSubmit}>
                交卷
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

PencilAnswer.propTypes = {
  showWrong: PropTypes.bool,
  answerList: PropTypes.object,
  answerListInt: PropTypes.array,
  scrollToQuestion: PropTypes.func,
  getProperAnswerPreview: PropTypes.func,
  needSubmitButton: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
};

PencilAnswer.defaultProps = {
  showWrong: false,
  answerList: {},
  answerListInt: [],
  scrollToQuestion: e => {},
  getProperAnswerPreview: e => {
    return "A";
  },
  needSubmitButton: true,
  color: "green",
  label: "答案卷",
};

export default PencilAnswer;
