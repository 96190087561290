import React from "react";
import PropTypes from "prop-types";

class NumberingRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="numbering-parent">
          <div
            id="container"
            className={`numbering ${this.props.color ?? "green"}`}
          >
            {this.props.number}
          </div>
          {this.props.rule !== "" ? (
            <div>{this.props.rule}</div>
          ) : (
            this.props.child
          )}
        </div>
        {this.props.showDivider ? (
          <div className="hori-divider" style={{ paddingTop: 16 }}></div>
        ) : null}
      </div>
    );
  }
}

NumberingRule.propTypes = {
  number: PropTypes.string,
  color: PropTypes.string,
  rule: PropTypes.string,
};

NumberingRule.defaultProps = {
  number: "1",
  color: "green",
  rule: "",
};

export default NumberingRule;
