import React from "react";
import ResultChar from "../../assets/images/result_char.png";
import ResultLike from "../../assets/images/result_like.svg";
import DialogPoint from "../../assets/images/dialog_point.svg";
import QuestionWithAnswer from "../widgets/QuestionWithAnswer";
import PencilAnswer from "../widgets/PencilAnswer";
import withRouter from "../../utilities/withRouter";
import {
  clientGetResult,
  clientGetResultPreview,
} from "../../utilities/Requests";
import { getProperAnswerPreview } from "../../utilities/Utilities";
import { PaperCNtoEN, StylingOptions } from "../../utilities/Config";
import BG_Green from "../../assets/images/bg_green.png";
import BG_Blue from "../../assets/images/bg_blue.jpg";
import BG_Purple from "../../assets/images/bg_purple.jpg";
import BG_Grey from "../../assets/images/bg_grey.jpg";

class QuizDetailResultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allAnswers: {},
      currentPaper: { questions: [] },
      answerHeaders: ["A", "B", "C", "D", "E"],
      mode: 1,
      answers: null,
      currentQuestion: 0,
    };

    this.setMode = this.setMode.bind(this);
    this.goPreviousPage = this.goPreviousPage.bind(this);
    this.goNextPage = this.goNextPage.bind(this);
    this.getPageNumber = this.getPageNumber.bind(this);
    this.scrollToQuestion = this.scrollToQuestion.bind(this);

    this.articleQuestionWithAnswer = React.createRef();
  }

  getBackground() {
    let color = this.getColor();
    if (color === "green") {
      return BG_Green;
    }
    if (color === "blue") {
      return BG_Blue;
    }
    if (color === "purple") {
      return BG_Purple;
    }
    if (color === "grey") {
      return BG_Grey;
    }
  }

  componentDidMount() {
    this.getQuizResult();
  }

  async getQuizResult() {
    let answerID = this.props.params.id;

    let result = {};
    if (this.props.params.preview) {
      this.setState({ isPreview: true });
      result = await clientGetResultPreview(
        answerID,
        this.props.params.preview
      );
    } else {
      result = await clientGetResult(answerID);
    }
    if (!result.paper) {
      window.location.href = "../404";
      // window.location.reload();
    }
    this.setState({ currentPaper: result.paper, answers: result.answers });
  }

  setMode(e) {
    this.setState({ mode: e });
  }

  getAnswerList() {
    var answerList = {};

    var currentCount = 0;

    if (!this.state.currentPaper) {
      return {};
    }
    for (var i = 0; i < this.state.currentPaper.questions.length; i++) {
      for (
        var j = 0;
        j < this.state.currentPaper.questions[i].all_questions.length;
        j++
      ) {
        let _ = this.state.currentPaper.questions[i].all_questions[j];
        var answerInfo = {};
        for (var k = 0; k < this.state.answers.answers.length; k++) {
          let eachAnswer = this.state.answers.answers[k];
          if (eachAnswer.id === _._id) {
            answerInfo = eachAnswer;
          }
        }

        answerList[currentCount] = answerInfo;
        currentCount += 1;
      }
    }
    Object.keys(this.state.allAnswers).forEach(eachAnswer => {
      answerList[eachAnswer] = this.state.allAnswers[eachAnswer];
    });

    return answerList;
  }

  getScore() {
    if (!this.state.answers) {
      return [0, 0, 0];
    }
    let total = this.state.answers.answers.length;
    var correct = 0;
    this.state.answers.answers.forEach(eachAnswer => {
      if (eachAnswer.correct === eachAnswer.answer) {
        correct += 1;
      }
    });

    return [correct, total, ((correct / total) * 100).toFixed(0)];
  }

  getStartingNumber(index) {
    var startingNumber = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (i === index) {
        return false;
      }
      startingNumber += eachQuestion.all_questions.length;
      return true;
    });
    return startingNumber;
  }

  getPageNumber(index) {
    var startingNumber = 0;
    var page = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (index >= startingNumber) {
        page = i;
        startingNumber += eachQuestion.all_questions.length;
        return true;
      }
      return false;
    });

    return page;
  }

  getColor() {
    let type = this.state.currentPaper.type;
    if (type === "閱讀單題") {
      return "green";
    }
    if (type === "語文") {
      return "blue";
    }
    if (type === "題組") {
      return "purple";
    }
    if (type === "模擬考") {
      return "grey";
    }
  }

  goPreviousPage() {
    this.setState(
      {
        currentQuestion: Math.max(0, this.state.currentQuestion - 1),
      },
      this.collapseExplanations
    );
  }

  goNextPage() {
    this.setState(
      {
        currentQuestion: Math.min(
          this.state.currentPaper.questions.length - 1,
          this.state.currentQuestion + 1
        ),
      },
      this.collapseExplanations
    );
  }

  collapseExplanations() {
    try {
      this.articleQuestionWithAnswer.current.autoExpand();
    } catch (e) {}
  }

  scrollToQuestion(question, id) {
    if (this.state.currentPaper.type === "題組") {
      this.setState({ currentQuestion: this.getPageNumber(question) });
    } else {
      window.document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  getNextURL() {
    try {
      var buttons =
        StylingOptions[PaperCNtoEN[this.state.currentPaper.type]]["partial"][
          "buttons"
        ];
      var returnValue = "";
      buttons.every(eachButton => {
        if (eachButton.title === this.state.currentPaper.category) {
          returnValue = eachButton.url;
        }
        return true;
      });
      return returnValue;
    } catch (e) {
      return "";
    }
  }

  render() {
    var answerList = this.getAnswerList();

    var score = this.getScore();
    return (
      <div
        id="details-result-container"
        className="center"
        style={{
          minHeight: this.props.maxHeight - 64 - 75,
          width: "100vw",
          padding: 32,
          boxSizing: "border-box",
          backgroundImage: `url(${this.getBackground()})`,
          overflow: "hidden",
        }}>
        <div className="flex" style={{ width: "fit-content" }}>
          <div className="">
            <div className="flex space-between rel" style={{ zIndex: 1 }}>
              <div className="flex flex-v space-between">
                <div
                  className="m-l-l"
                  style={{ transform: "translateY(24px)" }}>
                  <div className="details-result-score-text">
                    <span>成績</span>
                    <span className="details-result-score-number">
                      {score[2]}
                    </span>
                    <span>分</span>
                  </div>
                  <div className="flex m-m-t">
                    <img
                      className="details-result-score-like-img"
                      src={ResultLike}
                      alt="like"
                    />
                    <div className="details-result-score-rate">
                      <span>你的答對率為</span>
                      <span className="helvetica">{`${score[0]}/${score[1]}`}</span>
                    </div>
                  </div>
                </div>
                <div className="details-result-tab-parent">
                  {/* <div
                    onClick={() => {
                      this.setMode(0);
                    }}
                    className={`tab-button-green noselect ${this.getColor()}`}
                  >
                    答錯題目
                  </div> */}
                  <div
                    onClick={() => {
                      this.setMode(1);
                    }}
                    className="tab-button-yellow noselect">
                    全部題目
                  </div>
                </div>
              </div>
              {this.state.answers && (
                <div className="bounce-up">
                  <div className="next-quiz-chat-box fade-in-after-1s">
                    <div className="next-quiz-chat-box-backdrop">
                      <img
                        alt="point"
                        className="right-point"
                        src={DialogPoint}
                      />
                      <div className="right-point-cover"></div>
                      <div className="flex flex-v details-result-dialog-parent">
                        <span className="next-quiz-chat-box-title">
                          我想要再練習
                        </span>
                        <div className="orange-dialog-divider"></div>
                        <span className="next-quiz-chat-box-desc">
                          試試其他{this.state.currentPaper.type}試卷吧!
                        </span>
                        <a
                          href={`../quiz-rule/${this.getNextURL()}`}
                          className="red-button m-m-t self-align-center wiggle">
                          前往
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="next-quiz-chat-box-char-parent">
                    <img
                      alt="result"
                      className="next-quiz-chat-box-char"
                      src={ResultChar}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              id="main-scroller"
              style={{ zIndex: 99 }}
              className={`rel details-result-backdrop no-scroll${
                this.state.mode === 1 ? " all-details" : ""
              } ${this.getColor()}`}>
              {this.state.currentPaper.type === "題組"
                ? this.state.currentPaper.questions.length > 0 &&
                  this.state.currentPaper.questions[
                    this.state.currentQuestion
                  ] && (
                    <QuestionWithAnswer
                      ref={this.articleQuestionWithAnswer}
                      showWrong={true}
                      colorMode={this.state.mode}
                      rawHeight={true}
                      blockHeight={this.props.maxHeight - (235 + 108 + 75)}
                      key={`question-${0}`}
                      answerList={answerList}
                      number={this.getStartingNumber(
                        this.state.currentQuestion
                      )}
                      showExplanation={true}
                      question={
                        this.state.currentPaper.questions[
                          this.state.currentQuestion
                        ]
                      }
                    />
                  )
                : this.state.currentPaper.questions.map((eachQuestion, i) => {
                    return (
                      <QuestionWithAnswer
                        showWrong={true}
                        colorMode={this.state.mode}
                        rawHeight={true}
                        blockHeight={this.props.maxHeight - (235 + 108 + 75)}
                        key={`question-${i}`}
                        answerList={answerList}
                        number={i}
                        showExplanation={true}
                        question={eachQuestion}
                      />
                    );
                  })}
            </div>

            {this.state.currentPaper.type === "題組" && (
              <div className="center gap-l" style={{ marginTop: 14 }}>
                {this.state.currentQuestion > 0 && (
                  <div
                    onClick={this.goPreviousPage}
                    className={`${this.getColor()}-button secondary next-page-button noselect`}>
                    回上一頁
                  </div>
                )}
                {this.state.currentQuestion <
                  this.state.currentPaper.questions.length - 1 && (
                  <div
                    onClick={this.goNextPage}
                    className={`${this.getColor()}-button next-page-button noselect`}>
                    翻下一頁
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{ width: 240 }}></div>
        <div
          className="details-pencil"
          style={{
            transform: `translateY(max(0px, min(204px, calc(100vh - 204px - 321px)) - ${this.props.bodyScroll}px))`,
          }}>
          <PencilAnswer
            color={this.getColor()}
            showWrong={true}
            answerList={answerList}
            scrollToQuestion={this.scrollToQuestion}
            getProperAnswerPreview={getProperAnswerPreview}
            needSubmitButton={false}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(QuizDetailResultPage);
