import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../../utilities/Utilities";

class FlatButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getClassName() {
    var className = ["flat-button"];
    ["compact", "curve", "active", "mini", "fit"].forEach(eachAttribute => {
      if (this.props[eachAttribute]) {
        className.push(eachAttribute);
      }
    });
    return className.join(" ");
  }

  getButtonContent() {
    return (
      <div className={`flat-button-container ${this.props.fit ? "fit" : ""}`}>
        <div className={this.getClassName()} onClick={this.props.callback}>
          {this.props.label}
        </div>
      </div>
    );
  }

  render() {
    return this.props.href !== "" ? (
      <a className="unset-a" href={this.props.href}>
        {this.getButtonContent()}
      </a>
    ) : (
      this.getButtonContent()
    );
  }
}

FlatButton.propTypes = {
  label: PropTypes.string,
  compact: PropTypes.bool,
  active: PropTypes.bool,
  curve: PropTypes.bool,
  mini: PropTypes.bool,
  fit: PropTypes.bool,
  href: PropTypes.string,
  callback: PropTypes.func,
};

FlatButton.defaultProps = {
  label: "FlatButton",
  compact: false,
  active: false,
  curve: false,
  mini: false,
  fit: false,
  href: "",
  callback: () => {
    debugPrint("FlatButton pressed");
  },
};

export default FlatButton;
