import React from "react";
import PropTypes from "prop-types";
import MaximizeIcon from "../../assets/images/maximize_icon.svg";
import MinimizeIcon from "../../assets/images/minimize_icon.svg";
import ExpandGreen from "../../assets/images/expand_green.svg";
import CollapseGreen from "../../assets/images/collapse_green.svg";
import ExpandBrown from "../../assets/images/expand_brown.svg";
import CollapseBrown from "../../assets/images/collapse_brown.svg";
import APIPaths from "../../configs/APIPaths";
import { ANSWER_HEADERS } from "../../utilities/Config";
import {
  checkIfStringIsNotEmpty,
  getRealCategory,
  replaceContextForUnderline,
  stripNonAlphabetNumber,
  htmlHasContent,
} from "../../utilities/Utilities";

class QuestionWithAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: [],
      expand: {},
      articleExpand: true,
      animateLater: false,
    };

    this.expandCollapse = this.expandCollapse.bind(this);
    this.articleExpandCollapse = this.articleExpandCollapse.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.autoExpand = this.autoExpand.bind(this);
  }

  expandCollapse(i) {
    var expand = this.state.expand;
    expand[i] = !expand[i];
    this.setState({
      expand: expand,
    });
  }

  collapseAll() {
    this.setState({
      expand: {},
    });
  }

  autoExpand() {
    // if (this.props.showExplanation) {
    //   var expand = {};
    //   this.props.question.all_questions.forEach((eachQuestion, i) => {
    //     if (
    //       this.props.answerList[this.props.number + i]["answer"] !==
    //       ANSWER_HEADERS.indexOf(eachQuestion.correct_answer)
    //     ) {
    //       expand[i] = true;
    //     } else {
    //       expand[i] = false;
    //     }
    //   });
    //   this.setState({ expand: expand });
    // }
    if (this.props.expand) {
      this.setState({ articleExpand: false, animateLater: true });
    }
  }

  errorInSubQuestions() {
    var error = false;
    this.props.question.all_questions.every((eachQuestion, i) => {
      if (
        this.props.answerList[this.props.number + i]["answer"] !==
        ANSWER_HEADERS.indexOf(eachQuestion.correct_answer)
      ) {
        error = true;
      }
      return !error;
    });

    return error;
  }

  articleExpandCollapse() {
    this.setState({
      articleExpand: !this.state.articleExpand,
      animateLater: true,
    });
  }

  componentDidMount() {
    this.autoExpand();
  }

  getAnswerHeader(i) {
    if (i > this.props.answerHeader.length - 1) {
      return "-";
    }
    return this.props.answerHeader[i];
  }

  getEndIcon(i) {
    if (this.props.colorMode === 0) {
      if (this.state.expand[i]) {
        return CollapseGreen;
      }
      return ExpandGreen;
    }
    if (this.props.colorMode === 1) {
      if (this.state.expand[i]) {
        return CollapseBrown;
      }
      return ExpandBrown;
    }
  }

  isArticle() {
    return (
      this.props.question.type === "題組" ||
      this.props.question.mock_type === "題組"
    );
  }

  getSortedComponents(eachQuestion) {
    let components = {
      題: (
        <>
          <div
            className="question-with-answer-question"
            dangerouslySetInnerHTML={{
              __html: eachQuestion.header,
            }}></div>
          {this.props.question.description && (
            <div className="question-with-answer-description">
              {eachQuestion.description}
            </div>
          )}
        </>
      ),
      引: htmlHasContent(eachQuestion.description) && (
        <p
          key={`question-with-answer-context-${eachQuestion._id}`}
          className={`question-with-answer-context single clear-p`}
          dangerouslySetInnerHTML={{
            __html: replaceContextForUnderline(eachQuestion.description),
          }}></p>
      ),
      圖: eachQuestion.image !== "" && (
        <img
          key={`question-with-answer-image-${eachQuestion._id}`}
          className="w100"
          alt="question"
          src={APIPaths.publicEndpoint.concat(eachQuestion.image)}
        />
      ),
    };
    let sortation = eachQuestion.sortation ?? "題引圖";
    let sortedComponents = [];
    for (let i = 0; i < sortation.length; i++) {
      try {
        sortedComponents.push(components[sortation[i]]);
      } catch (err) {}
    }

    return sortedComponents;
  }

  render() {
    return (
      <div
        id={`question-${this.props.number}`}
        key={`question-${this.props.number}-${this.props.question._id}`}
        className={`question-with-answer-parent ${
          this.isArticle() ? "article" : ""
        } ${
          this.state.animateLater
            ? this.state.articleExpand
              ? "maximize"
              : "minimize"
            : ""
        }`}>
        {this.isArticle() && (
          <div
            id="question-with-answer"
            className="question-with-answer-expand-collapse-article-container"
            style={{
              height: this.props.rawHeight
                ? "auto"
                : `calc(${this.props.blockHeight}px - 4em - 64px)`,
            }}>
            <div
              className={`question-with-answer-expand-collapse-article noselect`}
              onClick={this.articleExpandCollapse}>
              {this.state.articleExpand ? (
                <img alt="expand collapse article" src={MinimizeIcon} />
              ) : null}
              <div className="">
                {this.state.articleExpand ? "縮小" : "展開"}文章
              </div>
              {!this.state.articleExpand ? (
                <img alt="expand collapse article" src={MaximizeIcon} />
              ) : null}
            </div>
            <div
              className={`question-with-answer-context ${
                this.props.rawHeight ? "no-scroll" : ""
              }`}>
              <p
                className={`question-with-answer-context single `}
                dangerouslySetInnerHTML={{
                  __html: replaceContextForUnderline(
                    this.props.question.context
                  ),
                }}></p>

              {this.props.question.image && (
                <img
                  className="w100"
                  alt="question"
                  src={APIPaths.publicEndpoint.concat(
                    this.props.question.image
                  )}
                />
              )}
              <p className="quote">{this.props.question.quote}</p>
            </div>
          </div>
        )}
        <div
          className={`question-with-answer-subcontainer ${
            this.isArticle() ? "no-h-scroll" : "no-scroll"
          }`}
          style={
            this.isArticle()
              ? {
                  height: this.props.rawHeight
                    ? "auto"
                    : `calc(${this.props.blockHeight}px - 4em - 64px)`,
                }
              : {}
          }>
          {this.props.question.all_questions &&
            this.props.question.all_questions.map((eachQuestion, i) => {
              return (
                <div
                  className="question-with-answer-container"
                  key={eachQuestion._id}
                  id={eachQuestion._id}>
                  <div className="question-with-answer-number">
                    {this.props.number + i + 1}.
                  </div>
                  <div className="w100">
                    <div className="question-with-answer-question-parent">
                      {this.getSortedComponents(eachQuestion)}
                      <div className="question-with-answer-answer-parent">
                        {Object.keys(eachQuestion.answers).map(
                          (eachAnswer, index) => (
                            <div
                              key={`answer-${eachQuestion._id}-${index}`}
                              className={`question-with-answer-answer  ${
                                this.props.colorMode >= 0 ? "no-answer" : ""
                              }`}
                              onClick={() => {
                                if (!this.props.showExplanation) {
                                  this.props.setAnswer(
                                    this.props.number + i,
                                    eachQuestion._id,
                                    index,
                                    ANSWER_HEADERS.includes(
                                      eachQuestion.correct_answer
                                    )
                                      ? ANSWER_HEADERS.indexOf(
                                          eachQuestion.correct_answer
                                        )
                                      : -1
                                  );
                                }
                              }}>
                              <div
                                className={`answer-ball ${this.props.color} ${
                                  this.props.answerList[this.props.number + i][
                                    "answer"
                                  ] === index
                                    ? "answer-ball-selected"
                                    : ""
                                } ${
                                  this.props.showWrong &&
                                  this.props.answerList[this.props.number + i][
                                    "answer"
                                  ] !==
                                    ANSWER_HEADERS.indexOf(
                                      eachQuestion.correct_answer
                                    )
                                    ? "wrong"
                                    : ""
                                }`}>
                                {eachAnswer}
                              </div>
                              <div className={`answer-line`}>
                                {eachQuestion.answers[eachAnswer]}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      {this.props.showExplanation ? (
                        <div
                          className={`question-with-answer-explanation-backdrop ${
                            this.state.expand[i]
                              ? "max-height-anim"
                              : "max-height-anim"
                          } ${
                            this.props.colorMode === 1 ? "all-details" : ""
                          }`}>
                          <div className="flex space-between m-b-s">
                            <div
                              className={`question-with-answer-explanation-title ${
                                this.props.colorMode === 1 ? "all-details" : ""
                              }`}>
                              {this.props.colorMode === 0
                                ? "查看詳解"
                                : "題目詳解"}
                            </div>
                            {this.props.colorMode === 0 && (
                              <img
                                onClick={() => {
                                  this.expandCollapse(i);
                                }}
                                alt="expand"
                                src={this.getEndIcon(i)}
                                className="button"
                                style={{ fill: "#008800" }}
                              />
                            )}
                            <div className="question-with-answer-category-tag">
                              {getRealCategory(
                                eachQuestion,
                                this.props.question
                              )}
                            </div>
                          </div>
                          {this.state.expand[i] ||
                            (this.props.colorMode === 1 && (
                              <div
                                className={`${
                                  this.state.expand[i] ? "fade-in" : ""
                                }`}>
                                <div className="dotted-divider-h"></div>
                                <p className="question-with-answer-explanation-answer">
                                  這題答案是
                                  {eachQuestion.correct_answer}。
                                </p>
                                {this.props.showDetailExplanation && (
                                  <>
                                    {" "}
                                    <p className="question-with-answer-explanation-description">
                                      {eachQuestion.explanation}
                                    </p>
                                    {checkIfStringIsNotEmpty(
                                      eachQuestion.source
                                    ) && (
                                      <p className="question-with-answer-explanation-source">
                                        出處｜
                                        {stripNonAlphabetNumber(
                                          eachQuestion.source
                                        )}
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>
                            ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

QuestionWithAnswer.propTypes = {
  colorMode: PropTypes.number,
  number: PropTypes.number,
  question: PropTypes.object,
  answerHeader: PropTypes.array,
  setAnswer: PropTypes.func,
  showExplanation: PropTypes.bool,
  showDetailExplanation: PropTypes.bool,
  color: PropTypes.string,
};

QuestionWithAnswer.defaultProps = {
  colorMode: -1,
  number: 0,
  question: {
    question: "試卷問題模板，請問以下的俗语，出自於誰之手？",
    description: "子非魚，焉知魚之樂",
    answers: {
      right: ["庄子·秋水"],
      wrong: ["令和·木村拓哉", "民國111年·蔡英文", "油管·蔡阿嘎"],
    },
    image: "",
    mode: 0,
  },
  answerHeader: ["A", "B", "C", "D", "E"],
  setAnswer: (q, a) => {},
  showExplanation: false,
  showDetailExplanation: true,
  color: "green",
};

export default QuestionWithAnswer;
