import React from "react";
import PropTypes from "prop-types";

class ContentTitle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="landing-content-title-parent">
        <div
          id="bottom-outline"
          className={`${this.props.isLarge ? "large" : ""}`}></div>
        <h1
          id={`content-title`}
          style={{
            whiteSpace: `${this.props.nowrap ? "nowrap" : "auto"}`,
          }}
          className={`${this.props.color} ${
            this.props.isLarge ? "large" : ""
          }`}>
          {this.props.label}
        </h1>
      </div>
    );
  }
}

ContentTitle.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

ContentTitle.defaultProps = {
  label: "Title",
  color: "",
};

export default ContentTitle;
