import React from "react";
import PropTypes from "prop-types";

import A1 from "../../assets/images/a1.svg";

import Star0 from "../../assets/images/star0.svg";
import Star1 from "../../assets/images/star1.svg";
import NavigateForwardIcon from "../../assets/images/navigate_forward_icon.svg";

class AbilityResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="flex center gap-xxl">
          <div className="ability-block">
            {/* <img alt="a1" src={this.props.icon} /> */}
            <div style={{ width: "1.5em" }}></div>
            <div className="ability-word">{this.props.label}</div>
            <div style={{ width: "1em" }}></div>
            <div className="star-block flex">
              {[...Array(this.props.starCount)].map((eachStar, i) => {
                return (
                  <img
                    key={`star0-${i}`}
                    className={`star result-star delay-${i}`}
                    alt="star"
                    src={Star1}
                  />
                );
              })}
              {[
                ...Array(
                  Math.max(0, this.props.totalStarCount - this.props.starCount)
                ),
              ].map((eachStar, i) => {
                return (
                  <img
                    key={`star1-${i}`}
                    className={`star result-star delay-${
                      i + this.props.starCount
                    }`}
                    alt="star"
                    src={Star0}
                  />
                );
              })}
            </div>
          </div>
          <a
            href={this.props.nextPage}
            className={`${
              this.props.color
            }-button result-green-button margin-h-m ${
              this.props.label === "檢測考題" ? "disabled-button" : ""
            }`}>
            前往
            <img className="filter-green" src={NavigateForwardIcon} alt="" />
          </a>
        </div>
        {this.props.needBottomBorder && <div className="bottom-border"></div>}
      </div>
    );
  }
}

AbilityResult.propTypes = {
  label: PropTypes.string,
  starCount: PropTypes.number,
  totalStarCount: PropTypes.number,
  icon: PropTypes.string,
  nextPage: PropTypes.string,
  needBottomBorder: PropTypes.bool,
  color: PropTypes.string,
};

AbilityResult.defaultProps = {
  label: "Title",
  starCount: 1,
  totalStarCount: 3,
  icon: A1,
  nextPage: "",
  needBottomBorder: true,
  color: "green",
};

export default AbilityResult;
