import React from "react";
import PageNotAvailable from "../../assets/images/page_not_available.svg";
import withRouter from "../../utilities/withRouter";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="center page-not-found-container">
        <div>
          <h1 className="half-black-text page-not-found-title">糟糕！</h1>
          <h2 className="half-black-text">我們似乎無法找到您要找的頁面。</h2>
          <h3 className="half-black-text">錯誤代碼：404</h3>
        </div>
        <img alt="page not found" src={PageNotAvailable} />
      </div>
    );
  }
}

export default withRouter(NotFoundPage);
