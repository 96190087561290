import React from "react";
import GoldTrophy from "../../assets/images/gold_trophy.png";
import SilverTrophy from "../../assets/images/silver_trophy.png";
import BronzeTrophy from "../../assets/images/bronze_trophy.png";
import BeginnerTrophy from "../../assets/images/beginner_trophy.png";
import ContentTitle from "../widgets/ContentTitle";
import AbilityResult from "../widgets/AbilityResult";
import withRouter from "../../utilities/withRouter";
import {
  clientGetResult,
  clientGetResultPreview,
} from "../../utilities/Requests";

import BG_Green from "../../assets/images/bg_green.png";
import BG_Blue from "../../assets/images/bg_blue.jpg";
import BG_Purple from "../../assets/images/bg_purple.jpg";
import {
  getProperAnswerPreview,
  getRealCategory,
  getRealType,
  mapValueRangeToNewRange,
} from "../../utilities/Utilities";
import QuestionWithAnswer from "../widgets/QuestionWithAnswer";
import { RulesOptions } from "../../utilities/Config";
import PencilAnswer from "../widgets/PencilAnswer";
class QuizBasicResultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trophy: 0,
      currentPaper: { questions: [] },
      abilityResult: {},
      allAnswers: [],
      mode: 1,
      currentQuestion: 0,
      pencilPosition: 0,
    };

    this.getAbilityResultInfo = this.getAbilityResultInfo.bind(this);
    this.goPreviousPage = this.goPreviousPage.bind(this);
    this.goNextPage = this.goNextPage.bind(this);
    this.scrollToQuestion = this.scrollToQuestion.bind(this);

    this.pencilTracker = null;
  }

  componentDidMount() {
    this.getQuizResult();
    this.trackPencil();
  }

  scrollToQuestion(question, id) {
    if (this.state.currentPaper.type === "題組") {
      this.setState({ currentQuestion: this.getPageNumber(question) });
    } else {
      window.document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  trackPencil() {
    this.pencilTracker = setInterval(() => {
      let questionContainer = document.getElementById("question-container");
      if (questionContainer) {
        let rect = questionContainer.getBoundingClientRect();
        if (rect.top !== this.state.pencilPosition) {
          this.setState({ pencilPosition: rect.top });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.pencilTracker);
  }

  async getQuizResult() {
    let answerID = this.props.params.id;
    let result = {};
    if (this.props.params.preview) {
      this.setState({ isPreview: true });
      result = await clientGetResultPreview(
        answerID,
        this.props.params.preview
      );
    } else {
      result = await clientGetResult(answerID);
    }
    if (!result.paper) {
      window.location.href = "../404";
    }

    this.setState(
      { currentPaper: result.paper, answers: result.answers },
      this.getAbilityResultInfo
    );
  }

  getAbilityResultInfo() {
    var answerList = {};

    var currentCount = 0;

    if (!this.state.currentPaper) {
      return {};
    }
    for (let i = 0; i < this.state.currentPaper.questions.length; i++) {
      for (
        var j = 0;
        j < this.state.currentPaper.questions[i].all_questions.length;
        j++
      ) {
        let _ = this.state.currentPaper.questions[i].all_questions[j];
        var answerInfo = {};
        for (var k = 0; k < this.state.answers.answers.length; k++) {
          let eachAnswer = this.state.answers.answers[k];
          if (eachAnswer.id === _._id) {
            answerInfo = eachAnswer;
            answerInfo["category"] = getRealCategory(
              _,
              this.state.currentPaper.questions[i]
            );
            answerInfo["type"] = getRealType(
              _,
              this.state.currentPaper.questions[i]
            );
          }
        }

        answerList[currentCount] = answerInfo;
        currentCount += 1;
      }
    }

    let abilityResult = {};
    // loop through all the answers
    var score = 0;
    var total = 0;
    for (let i = 0; i < Object.keys(answerList).length; i++) {
      let eachAnswer = answerList[i];
      let category = eachAnswer.category;
      let correct = eachAnswer.answer === eachAnswer.correct;
      score += correct;
      total += 1;
      if (!abilityResult[category]) {
        abilityResult[category] = {
          correct: correct ? 1 : 0,
          total: 1,
        };
      } else {
        abilityResult[category].correct += correct;
        abilityResult[category].total += 1;
      }
    }
    // map score that has the value from 0 to 1 to 1 to 3 dynamically
    // 0 -> 1
    // 1 -> 3
    // 0.5 -> 2
    let trophy = mapValueRangeToNewRange(score / total, 0, 1, 1, 3);
    if (this.state.currentPaper.type === "閱讀單題") {
      // score = 11 and above
      if (score >= 11) {
        trophy = 3;
      }
      // score = 6 ~ 10
      else if (score >= 6) {
        trophy = 2;
      }
      // score = 1 ~ 5
      else if (score >= 2) {
        trophy = 1;
      }
      // score = 0
      else {
        trophy = 0;
      }
    }
    if (this.state.currentPaper.type === "語文") {
      // score = 9 and above
      if (score >= 9) {
        trophy = 3;
      }
      // score = 5 ~ 8
      else if (score >= 5) {
        trophy = 2;
      }
      // score = 1 ~ 4
      else if (score >= 2) {
        trophy = 1;
      }
      // score = 0
      else {
        trophy = 0;
      }
    }
    if (this.state.currentPaper.type === "題組") {
      // score = 11 and above
      if (score >= 11) {
        trophy = 3;
      }
      // score = 6 ~ 10
      else if (score >= 6) {
        trophy = 2;
      }
      // score = 1 ~ 5
      else if (score >= 2) {
        trophy = 1;
      }
      // score = 0
      else {
        trophy = 0;
      }
    }

    this.setState({ abilityResult: abilityResult, trophy: trophy });
  }

  getColor() {
    let type = this.state.currentPaper.type;
    if (type === "閱讀單題") {
      return "green";
    }
    if (type === "語文") {
      return "blue";
    }
    if (type === "題組") {
      return "purple";
    }
  }

  getBackground() {
    let color = this.getColor();
    if (color === "green") {
      return BG_Green;
    }
    if (color === "blue") {
      return BG_Blue;
    }
    if (color === "purple") {
      return BG_Purple;
    }
  }

  properTrophy() {
    switch (this.state.trophy) {
      case 0:
      default:
        return BeginnerTrophy;
      case 1:
        return BronzeTrophy;
      case 2:
        return SilverTrophy;
      case 3:
        return GoldTrophy;
    }
  }

  getAnswerList() {
    if (!this.state.currentPaper) {
      return {};
    }
    var answerList = {};

    var currentCount = 0;

    if (!this.state.currentPaper) {
      return {};
    }
    for (var i = 0; i < this.state.currentPaper.questions.length; i++) {
      for (
        var j = 0;
        j < this.state.currentPaper.questions[i].all_questions.length;
        j++
      ) {
        let _ = this.state.currentPaper.questions[i].all_questions[j];
        var answerInfo = {};
        for (var k = 0; k < this.state.answers.answers.length; k++) {
          let eachAnswer = this.state.answers.answers[k];
          if (eachAnswer.id === _._id) {
            answerInfo = eachAnswer;
          }
        }

        answerList[currentCount] = answerInfo;
        currentCount += 1;
      }
    }
    Object.keys(this.state.allAnswers).forEach(eachAnswer => {
      answerList[eachAnswer] = this.state.allAnswers[eachAnswer];
    });

    return answerList;
  }

  goPreviousPage() {
    this.setState(
      {
        currentQuestion: Math.max(0, this.state.currentQuestion - 1),
      },
      this.collapseExplanations
    );
  }

  goNextPage() {
    this.setState(
      {
        currentQuestion: Math.min(
          this.state.currentPaper.questions.length - 1,
          this.state.currentQuestion + 1
        ),
      },
      this.collapseExplanations
    );
  }

  getStartingNumber(index) {
    var startingNumber = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (i === index) {
        return false;
      }
      startingNumber += eachQuestion.all_questions.length;
      return true;
    });
    return startingNumber;
  }

  getPageNumber(index) {
    var startingNumber = 0;
    var page = 0;
    this.state.currentPaper.questions.every((eachQuestion, i) => {
      if (index >= startingNumber) {
        page = i;
        startingNumber += eachQuestion.all_questions.length;
        return true;
      }
      return false;
    });

    return page;
  }

  getAbilityPage(ability) {
    let returnValue = "";
    Object.keys(RulesOptions).forEach(eachType => {
      if (RulesOptions[eachType].category === ability) {
        returnValue = eachType;
      }
    });
    return returnValue;
  }

  getPencilLabel() {
    // try {
    //   if (
    //     this.state.currentPaper.type === undefined ||
    //     this.state.currentPaper.category === undefined ||
    //     this.state.currentPaper.title === undefined
    //   ) {
    //     return "答案卷";
    //   }
    //   return `${this.state.currentPaper.category}${this.state.currentPaper.title} 答案卷`;
    // } catch (e) {
    //   return "答案卷";
    // }
    return "答案卷";
  }

  render() {
    var answerList = this.getAnswerList();
    // console.log(
    //   this.state.currentPaper.type === "題組",
    //   this.state.currentPaper.questions.length > 0,
    //   this.state.currentPaper.questions[this.state.currentQuestion]
    // );
    return (
      <div
        className="basic-result-page-parent"
        style={{
          backgroundImage: `url(${this.getBackground()})`,
        }}>
        <div
          className="center result-page-parent"
          style={{
            // minHeight: this.props.maxHeight - 75,
            width: "100vw",
            padding: 32,
            boxSizing: "border-box",
            overflow: "hidden",
          }}>
          <div className="full-wh center">
            <div className="result-content-area">
              <div
                className="two-third-width center-v"
                style={{ paddingTop: 32 }}>
                <div className="center">
                  <ContentTitle
                    label={`你的${this.state.currentPaper.type ?? ""}能力是`}
                    isLarge={true}
                    color={this.getColor()}
                  />
                </div>
                <img
                  alt="trophy"
                  className="trophy"
                  src={this.properTrophy()}
                />
              </div>
              <div className="result-ability-backdrop">
                <div className="flex space-around">
                  <div className={`result-ability-label ${this.getColor()}`}>
                    {this.state.currentPaper.type}能力分析
                  </div>
                  <div className={`result-ability-label ${this.getColor()}`}>
                    更多練習題
                  </div>
                </div>
                {Object.keys(this.state.abilityResult).map((ability, index) => {
                  return (
                    <AbilityResult
                      key={`ability-${index}`}
                      nextPage={`../quiz-rule/${this.getAbilityPage(ability)}`}
                      label={ability}
                      starCount={mapValueRangeToNewRange(
                        this.state.abilityResult[ability].correct /
                          this.state.abilityResult[ability].total,
                        0,
                        1,
                        1,
                        3
                      )}
                      needBottomBorder={
                        index !==
                        Object.keys(this.state.abilityResult).length - 1
                      }
                      color={this.getColor()}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          id="question-container"
          style={{ display: "flex", padding: "0 1em", gap: "1em" }}>
          <div>
            <div style={{ width: "100%", maxWidth: "calc(1920px - 2em)" }}>
              <div className="details-result-tab-parent">
                {/* <div
                    onClick={() => {
                      this.setMode(0);
                    }}
                    className={`tab-button-green noselect ${this.getColor()}`}
                  >
                    答錯題目
                  </div> */}
                <div
                  onClick={() => {
                    // this.setMode(1);
                  }}
                  className="tab-button-yellow noselect">
                  全部題目
                </div>
              </div>
            </div>

            <div
              id="main-scroller"
              style={{
                zIndex: 99,
                maxWidth: 1920,
                margin: "0em 1em 0em 1em",
                // backgroundImage: `url(${this.getBackground()})`,
              }}
              className={`rel details-result-backdrop no-scroll${
                this.state.mode === 1 ? " all-details" : ""
              } ${this.getColor()}`}>
              {this.state.currentPaper.type === "題組"
                ? this.state.currentPaper.questions.length > 0 &&
                  this.state.currentPaper.questions[
                    this.state.currentQuestion
                  ] && (
                    <QuestionWithAnswer
                      ref={this.articleQuestionWithAnswer}
                      showWrong={true}
                      colorMode={this.state.mode}
                      rawHeight={true}
                      blockHeight={this.props.maxHeight - (235 + 108 + 75)}
                      key={`question-${0}`}
                      answerList={answerList}
                      number={this.getStartingNumber(
                        this.state.currentQuestion
                      )}
                      showExplanation={true}
                      showDetailExplanation={false}
                      question={
                        this.state.currentPaper.questions[
                          this.state.currentQuestion
                        ]
                      }
                    />
                  )
                : this.state.currentPaper.questions.map((eachQuestion, i) => {
                    return (
                      <QuestionWithAnswer
                        showWrong={true}
                        colorMode={this.state.mode}
                        rawHeight={true}
                        blockHeight={this.props.maxHeight - (235 + 108 + 75)}
                        key={`question-${i}`}
                        answerList={answerList}
                        number={i}
                        showExplanation={true}
                        showDetailExplanation={false}
                        question={eachQuestion}
                      />
                    );
                  })}
            </div>
          </div>
          <div style={{ width: 175, minWidth: 175 }}></div>
        </div>

        <div
          style={{
            position: "fixed",
            top: Math.max(75, this.state.pencilPosition),
            right: "1em",
          }}>
          <p></p>
          <PencilAnswer
            label={this.getPencilLabel()}
            color={this.getColor()}
            showWrong={true}
            answerList={answerList}
            scrollToQuestion={this.scrollToQuestion}
            getProperAnswerPreview={getProperAnswerPreview}
            needSubmitButton={false}
          />
        </div>
        {this.state.currentPaper.type === "題組" && (
          <div
            className="center gap-l"
            style={{ marginTop: 14, paddingBottom: 14 }}>
            {this.state.currentQuestion > 0 && (
              <div
                onClick={this.goPreviousPage}
                className={`${this.getColor()}-button secondary next-page-button noselect`}>
                回上一頁
              </div>
            )}
            {this.state.currentQuestion <
              this.state.currentPaper.questions.length - 1 && (
              <div
                onClick={this.goNextPage}
                className={`${this.getColor()}-button next-page-button noselect`}>
                翻下一頁
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(QuizBasicResultPage);
