import React from "react";
import Lottie from "lottie-react";

const LottieAnimation = props => {
  console.log(props.animationData);

  return <Lottie animationData={props.animationData} loop={true} />;
};

export default LottieAnimation;
