// import APIHeader from "../configs/APIHeader.js";
import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";
import { debugPrint } from "./Utilities";

async function callAPI(body, target, method = "POST", defaultReturn) {
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      // apikey: APIHeader.apiKey,
    },
    body: method === "GET" ? null : body,
  };

  debugPrint(`TARGET - [${target}]\nBODY - ${JSON.stringify(body)}`, "orange");

  var proceed = true;

  // Object.values(body).forEach((param) => {
  //   if (param === null || param === undefined) {
  //     proceed = false;
  //   }
  // });

  if (proceed) {
    await new Promise(resolve => setTimeout(resolve, 500));
    return await fetch(target, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        // debugPrint(JSON.stringify(responseData), "green");
        try {
          if (responseData.status === APIResult.INVALID_SESSION) {
            // createBrowserHistory().push("/login?to=1");
            window.location.reload();
          }
        } catch (e) {
          debugPrint(e);
        }
        return responseData;
      })
      .catch(error => {
        console.warn(error);
        return defaultReturn;
      });
  } else {
    return defaultReturn;
  }
}

export async function clientGetPaperList() {
  var target = APIPaths.apiEndPoint.concat(APIPaths.clientGetPaperList);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function clientReadPaper(id) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.clientReadPaper)
    .concat(`/${id}`);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}
export async function clientReadPaperPreview(id, previewCode) {
  console.log("hihi");
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.clientReadPaperPreview)
    .concat(`/${id}/${previewCode}`);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function clientSubmitAnswer(id, answers, client, timeTaken) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.clientSubmitAnswer)
    .concat(`/${id}`);
  return callAPI(
    JSON.stringify({ answers: answers, client: client, time_taken: timeTaken }),
    target,
    "POST",
    {
      status: APIResult.TIMED_OUT,
      status_message: "-",
    }
  );
}

export async function clientGetResult(id) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.clientGetResult)
    .concat(`/${id}`);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}

export async function clientGetResultPreview(id, previewCode) {
  var target = APIPaths.apiEndPoint
    .concat(APIPaths.clientGetResultPreview)
    .concat(`/${id}/${previewCode}`);

  return callAPI({}, target, "GET", {
    status: APIResult.TIMED_OUT,
    status_message: "-",
  });
}
