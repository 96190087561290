import React from "react";
import PropTypes from "prop-types";
import DialogHeaderGreen from "../../assets/images/dialog_header_green.svg";
import DialogHeaderBlue from "../../assets/images/dialog_header_blue.svg";
import DialogHeaderPurple from "../../assets/images/dialog_header_purple.svg";
import DialogHeaderGrey from "../../assets/images/dialog_header_grey.svg";
import MissedAnswerGreen from "../../assets/images/missed_answer_green.png";
import MissedAnswerBlue from "../../assets/images/missed_answer_blue.png";
import MissedAnswerPurple from "../../assets/images/missed_answer_purple.png";
import MissedAnswerGrey from "../../assets/images/missed_answer_grey.png";

class MissingAnswerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getMissingAnswersText() {
    var toBeConcat = [];
    this.props.missingAnswers.forEach(eachAnswer => {
      toBeConcat.push(`第${eachAnswer + 1}題`);
    });
    toBeConcat.splice(2, this.props.missingAnswers.length);

    var final = toBeConcat.join("、");
    if (this.props.missingAnswers.length > 2) {
      final += "以及其他";
    }
    return final;
  }

  getImage(type) {
    if (type === "header") {
      if (this.props.color === "green") {
        return DialogHeaderGreen;
      }
      if (this.props.color === "blue") {
        return DialogHeaderBlue;
      }
      if (this.props.color === "purple") {
        return DialogHeaderPurple;
      }
      if (this.props.color === "grey") {
        return DialogHeaderGrey;
      }
      return DialogHeaderGreen;
    }
    if (type === "missed") {
      if (this.props.color === "green") {
        return MissedAnswerGreen;
      }
      if (this.props.color === "blue") {
        return MissedAnswerBlue;
      }
      if (this.props.color === "purple") {
        return MissedAnswerPurple;
      }
      if (this.props.color === "grey") {
        return MissedAnswerGrey;
      }
      return MissedAnswerGreen;
    }
  }

  render() {
    return (
      <div className="blocker force-width fade-in">
        <div className={`dialog-container ${this.props.color}`}>
          <div className="rel">
            <img
              className="dialog-header-img"
              src={this.getImage("header")}
              alt="dialog-header"
            />
            <img
              className="dialog-header-char"
              src={this.getImage("missed")}
              alt="dialog-header-char"
            />
            <div className="dialog-header-title">哎呀！漏題了</div>
          </div>
          <div className="dialog-green-content">
            <span className="dialog-content">
              你的
              <span className="dialog-content-mention">
                {this.getMissingAnswersText()}
              </span>
              尚未作答
            </span>
            <div className="dialog-buttons-parent">
              <div
                className={`${this.props.color}-button button-fit`}
                onClick={this.props.returnFunc}>
                返回作答
              </div>
              <div
                className={`${this.props.color}-button secondary button-fit`}
                onClick={this.props.submitFunc}>
                我要交卷
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MissingAnswerDialog.propTypes = {
  color: PropTypes.string,
  missingAnswers: PropTypes.array,
  returnFunc: PropTypes.func,
  submitFunc: PropTypes.func,
};

MissingAnswerDialog.defaultProps = {
  color: "green",
  missingAnswers: [],
  returnFunc: () => {},
  submitFunc: () => {},
};

export default MissingAnswerDialog;
