import React from "react";
import PropTypes from "prop-types";
import TextButton from "./TextButton";
// import SearchBar from "./SearchBar";
import Logo from "../../assets/images/logo192.png";
import { debugPrint } from "../../utilities/Utilities";

class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="top-bar">
        <a className="a-clear" href="https://www.jyreading.com">
          <h3 className="top-bar-title site-name-left-padding">
            財團法人江雲教育基金會
          </h3>
        </a>
        <img className="top-bar-icon" src={Logo} alt="top bar icon" />
        <div className="top-bar-menu">
          <TextButton label="首頁" url="https://www.jyreading.com/" />
          <TextButton
            label="關於我們"
            url="https://www.jyreading.com/mission"
          />
          <TextButton
            label="國小閱讀"
            url="https://www.jyreading.com/elementary-reading-explanation"
          />
          <TextButton
            label="國中閱讀"
            url="https://www.jyreading.com/junior-reading-explanation"
          />
          <TextButton
            label="高中閱讀"
            url="https://www.jyreading.com/1001reading"
          />
          <TextButton
            label="試題分級分類"
            url="https://www.jyreading.com/grade-explanation"
          />
          <TextButton
            hoverMenu={{
              閱讀單題測驗: {
                url: "https://quiz.jyreading.com/landing/reading",
                enabled: true,
              },
              語文單題測驗: {
                url: "https://quiz.jyreading.com/landing/language",
                enabled: true,
              },
              閱讀題組測驗: {
                url: "https://quiz.jyreading.com/landing/article",
                enabled: true,
              },
              線上模擬考: {
                url: "https://quiz.jyreading.com/quiz-rule/mock-paper",
                enabled: true,
              },
            }}
            label="會考檢測"
            url=""
          />
          {/* <SearchBar hint="站內搜尋" /> */}
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
};

TopBar.defaultProps = {
  label: "Button",
  callback: () => {
    debugPrint("Text Button pressed");
  },
};

export default TopBar;
