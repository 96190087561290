const APIPaths = {
  apiEndPoint: "https://api.jyreading.com/",
  // apiEndPoint: "http://localhost:15080/",
  publicEndpoint: "https://public.jyreading.com/images/",
  clientGetPaperList: "api/client/get_paper_list",
  clientReadPaper: "api/client/read_paper",
  clientReadPaperPreview: "api/client/read_paper_preview",
  clientSubmitAnswer: "api/client/submit_answer",
  clientGetResult: "api/client/get_result",
  clientGetResultPreview: "api/client/get_result_preview",
};

export default APIPaths;
