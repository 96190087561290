import React from "react";
import FullReadingAbilityCheck from "../../assets/images/full_reading_ability_check.png";
import PartialReadingAbilityCheck from "../../assets/images/partial_reading_ability_check.png";
import FullLanguageAbilityCheck from "../../assets/images/full_language_ability_check.png";
import PartialLanguageAbilityCheck from "../../assets/images/partial_language_ability_check.png";
import FullArticleAbilityCheck from "../../assets/images/full_article_ability_check.png";
import PartialArticleAbilityCheck from "../../assets/images/partial_article_ability_check.png";
import withRouter from "../../utilities/withRouter";
import ContentTitle from "../widgets/ContentTitle";
import {
  PaperENtoCN,
  PaperNaming,
  StylingOptions,
} from "../../utilities/Config";
import { clientGetPaperList } from "../../utilities/Requests";
import APIResult from "../../configs/APIResult";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: PaperNaming.READING.en,
      paperCategoryAvailable: [],
    };

    this.getPaperList = this.getPaperList.bind(this);
  }

  componentDidMount() {
    let mode = this.props.params.id;
    if (!mode) {
      mode = "reading";
    }
    if (mode === "mock-paper") {
      window.location.href = `../quiz-rule/mock-paper`;
    }
    this.setState({ mode: mode }, this.getPaperList);
  }

  async getPaperList() {
    let result = await clientGetPaperList();
    if (result.status === APIResult.SUCCESS) {
      let availablePapers = result.data;
      this.setState({ paperCategoryAvailable: availablePapers });
    }
  }

  categoryAvailable(category) {
    var availability = false;
    this.state.paperCategoryAvailable.every(eachPaperCategoryAvailable => {
      if (eachPaperCategoryAvailable.type === PaperENtoCN[this.state.mode]) {
        if (category === eachPaperCategoryAvailable.category) {
          availability = true;
          return false;
        }
      }
      return true;
    });
    return availability;
  }

  getAbilityImage(type) {
    switch (type) {
      case 0:
      default:
        if (this.state.mode === PaperNaming.LANGUAGE.en) {
          return FullLanguageAbilityCheck;
        }
        if (this.state.mode === PaperNaming.ARTICLE.en) {
          return FullArticleAbilityCheck;
        }
        return FullReadingAbilityCheck;
      case 1:
        if (this.state.mode === PaperNaming.LANGUAGE.en) {
          return PartialLanguageAbilityCheck;
        }
        if (this.state.mode === PaperNaming.ARTICLE.en) {
          return PartialArticleAbilityCheck;
        }
        return PartialReadingAbilityCheck;
    }
  }

  render() {
    var full = (StylingOptions[this.state.mode] ??
      StylingOptions[PaperNaming.READING.en])["full"];
    var partial = (StylingOptions[this.state.mode] ??
      StylingOptions[PaperNaming.READING.en])["partial"];
    return (
      <div
        style={{
          paddingTop: 64,
          minHeight: this.props.maxHeight - 64 - 75,
          backgroundColor: (StylingOptions[this.state.mode] ??
            StylingOptions["reading"])["color"],
        }}>
        <div className="content-area ">
          <div style={{ marginBottom: "7.5vh" }} className="center">
            <ContentTitle
              label={StylingOptions[this.state.mode].title}
              isLarge={true}
              color={
                (StylingOptions[this.state.mode] ?? StylingOptions["reading"])[
                  "titleColorBasic"
                ]
              }
            />
          </div>
          <div className="landing-ability-options-parent">
            <div className="landing-ability-img-parent">
              <img
                className="landing-ability-img"
                alt="full ability"
                src={this.getAbilityImage(0)}
              />
              <div className="hori-buttons">
                {full["buttons"].map(eachButton => {
                  return (
                    <a
                      key={`button-${eachButton.title}`}
                      href={`../quiz-rule/${eachButton.url}`}
                      className={`${full.class}`}>
                      {eachButton.title}
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="landing-ability-img-parent">
              <img
                className="landing-ability-img"
                alt="partial ability"
                src={this.getAbilityImage(1)}
              />
              <div className="hori-buttons">
                {partial["buttons"].map(eachButton => {
                  if (!this.categoryAvailable(eachButton.title)) {
                    return null;
                  }
                  return (
                    <a
                      key={`button-${eachButton.title}`}
                      href={`../quiz-rule/${eachButton.url}`}
                      className={`${partial.class} ${
                        this.categoryAvailable(eachButton.title)
                          ? ""
                          : "button-disable"
                      }`}>
                      {eachButton.title}
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="vertical-divider"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LandingPage);
