import "./App.css";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import LandingPage from "./components/pages/LandingPage";
import "./master.scss";
import TopBar from "./components/widgets/TopBar";
import QuizRulePage from "./components/pages/QuizRulePage";
import QuizPage from "./components/pages/QuizPage";
import QuizBasicResultPage from "./components/pages/QuizBasicResultPage";
import QuizDetailResultPage from "./components/pages/QuizDetailsResultPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import packageJson from "../package.json";
import QuizMockPaperResultPage from "./components/pages/QuizMockPaperResultPage";
import MaintenanceAnimation from "./assets/lottie/maintenance.json";
import LottieAnimation from "./components/widgets/LottieAnimation";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { maxHeight: 0, bodyScroll: 0, maintenanceMode: false };
  }

  componentDidMount() {
    this.setState({ maxHeight: window.innerHeight });
    window.addEventListener("resize", () => {
      this.setState({ maxHeight: window.innerHeight });
    });
    window.addEventListener("scroll", () => {
      this.setState({ bodyScroll: window.scrollY });
    });
  }

  needScroller() {
    if (
      window.location.pathname.includes("/basic-result/") ||
      window.location.pathname.includes("/mock-paper-result/")
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { maxHeight, bodyScroll, maintenanceMode } = this.state;
    return (
      <div
        style={{ overflowX: "hidden" }}
        className={`force-width ${this.needScroller() ? "scroller" : ""}`}>
        <TopBar />
        {maintenanceMode ? (
          <div>
            <div className="maintenance">
              <div className="maintenance-text">
                <h1>系統維護</h1>
                <div
                  style={{
                    width: 512,
                    maxWidth: "80vw",
                    maxHeight: "80vh",
                  }}>
                  <LottieAnimation animationData={MaintenanceAnimation} />
                </div>
                <p>網站正在進行維護，請稍後再嘗試。</p>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: 75 }}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/landing/:id"
                  element={<LandingPage maxHeight={maxHeight} />}
                />
                <Route
                  path="/"
                  element={<LandingPage maxHeight={maxHeight} />}
                />
                <Route
                  path="/quiz-rule/:id"
                  element={<QuizRulePage maxHeight={maxHeight} />}
                />
                <Route
                  path="/quiz/:id/:preview?"
                  element={<QuizPage maxHeight={maxHeight} />}
                />
                <Route
                  path="/basic-result/:id/:preview?"
                  element={<QuizBasicResultPage maxHeight={maxHeight} />}
                />
                <Route
                  path="/details-result/:id/:preview?"
                  element={
                    <QuizDetailResultPage
                      maxHeight={maxHeight}
                      bodyScroll={bodyScroll}
                    />
                  }
                />
                <Route
                  path="/mock-paper-result/:id/:preview?"
                  element={
                    <QuizMockPaperResultPage
                      maxHeight={maxHeight}
                      bodyScroll={bodyScroll}
                    />
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </BrowserRouter>
          </div>
        )}
        <div className="version">v{packageJson.version}</div>
      </div>
    );
  }
}

export default App;
