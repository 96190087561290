import React from "react";
import BG_Green from "../../assets/images/bg_green.png";
import BG_Blue from "../../assets/images/bg_blue.jpg";
import BG_Purple from "../../assets/images/bg_purple.jpg";
import BG_Grey from "../../assets/images/bg_grey.jpg";
import { RulesOptions } from "../../utilities/Config";
import withRouter from "../../utilities/withRouter";
import ContentTitle from "../widgets/ContentTitle";
import NumberingRule from "../widgets/NumberingRule";
import APIResult from "../../configs/APIResult";
import { clientGetPaperList } from "../../utilities/Requests";

class QuizRulePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "reading-full",
      availablePapers: [],
      currentPaper: {},
    };

    this.getDifficultyColor = this.getDifficultyColor.bind(this);
  }

  componentDidMount() {
    this.setState(
      { mode: this.props.params.id ?? "reading-full" },
      this.getPaperList
    );
  }

  isTypeCategoryMatched(option) {
    return (
      (RulesOptions[this.state.mode].type === option.type &&
        RulesOptions[this.state.mode].category === option.category) ||
      (RulesOptions[this.state.mode].type === option.type &&
        RulesOptions[this.state.mode].type === "模擬考")
    );
  }

  async getPaperList() {
    let result = await clientGetPaperList();
    if (result.status === APIResult.SUCCESS) {
      let allAvailablePapers = result.data;
      var availablePapers = [];
      allAvailablePapers.every(option => {
        if (this.isTypeCategoryMatched(option)) {
          availablePapers.push(option);
        }
        return true;
      });
      var currentPaper = {};
      if (availablePapers.length > 0) {
        currentPaper = availablePapers[0];
      }
      this.setState({
        availablePapers: availablePapers,
        currentPaper: currentPaper,
      });
    }
  }

  selectCurrentPaper(id) {
    var currentPaper = {};
    this.state.availablePapers.every(eachPaper => {
      if (eachPaper._id === id) {
        currentPaper = eachPaper;
        return false;
      }
      return true;
    });
    this.setState({ currentPaper: currentPaper });
  }

  getColor() {
    return (RulesOptions[this.state.mode] ?? RulesOptions["reading-full"])[
      "color"
    ];
  }

  getBackground() {
    let color = this.getColor();
    if (color === "green") {
      return BG_Green;
    }
    if (color === "blue") {
      return BG_Blue;
    }
    if (color === "purple") {
      return BG_Purple;
    }
    if (color === "grey") {
      return BG_Grey;
    }
  }

  getDifficultyColor(index) {
    var color = "";
    this.state.availablePapers.every(eachPaper => {
      if (this.state.currentPaper._id === eachPaper._id) {
        color =
          index < (eachPaper.difficulty ?? 0) ? `color-${this.getColor()}` : "";
        return false;
      }
      return true;
    });

    return color;
  }

  render() {
    var rulesGroup =
      RulesOptions[this.state.mode] ?? RulesOptions["reading-full"];
    return (
      <div
        style={{
          paddingTop: 64,
          backgroundImage: `url(${this.getBackground()})`,
          minHeight: this.props.maxHeight - 64 - 75,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div className="content-area align-item-center">
          <div style={{ marginBottom: "7.5vh" }} className="center">
            <ContentTitle label={rulesGroup.label} color={this.getColor()} />
          </div>
          <div
            className="rule-title title-overflow-y m-m-t"
            style={{ color: rulesGroup.titleColor }}>
            測驗說明
          </div>
          <div className="rule-backdrop">
            {rulesGroup.rules.map((eachRule, i) => {
              return (
                <NumberingRule
                  key={this.state.mode + i}
                  color={rulesGroup.color}
                  number={(i + 1).toString()}
                  rule={eachRule}
                  showDivider={i < rulesGroup.rules.length - 1}
                />
              );
            })}
          </div>

          {this.state.availablePapers.length > 1 && (
            <div className="select-paper-container m-m-t">
              <p>我要做</p>
              <div>
                <select
                  className={`select-${
                    (RulesOptions[this.state.mode] ??
                      RulesOptions["reading-full"])["color"]
                  }`}
                  onChange={e => {
                    this.selectCurrentPaper(e.target.value);
                  }}
                  value={this.state.currentPaper._id}
                  id="questions"
                  title="questions">
                  {this.state.availablePapers.map(option => {
                    if (this.isTypeCategoryMatched(option)) {
                      return (
                        <option key={option._id} value={option._id}>
                          {option.title}
                        </option>
                      );
                    } else {
                      return null;
                    }
                  })}
                </select>
              </div>
              <p>試卷</p>

              {(this.state.currentPaper.difficulty ?? 0) > 1 && (
                <div className="select-paper-difficulty-container">
                  <p>，難度</p>
                  <div className="select-paper-difficulty-container">
                    {[...Array(5).keys()].map(index => {
                      return (
                        <div
                          key={`difficulty-${index}`}
                          className={`difficulty-star-default ${this.getDifficultyColor(
                            index
                          )}`}>
                          ★
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
          <a
            href={`../quiz/${this.state.currentPaper._id}`}
            className={`${rulesGroup.color}-button m-m-t ${
              this.state.availablePapers.length > 0 ? "" : "disabled-button"
            }`}>
            開始測驗
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(QuizRulePage);
